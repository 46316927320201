import React from 'react';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import field from 'lib/field';
import createPushTaskMutation from 'features/pushes/queries/createPushTaskMutation.gql';
import { crudMutate } from 'features/common/helpers';
import LocalStorage from 'features/store/LocalStorage';

import { IFormValues } from 'features/pushes/types';
import { IPushes } from 'features/types';

import { PUSH_OFFERS } from 'features/pushes/consts';
import BaseForm from './Form';

interface IFormProps extends WithTranslation {
  data?: IPushes;
}

class Form extends React.PureComponent<IFormProps> {
  onSubmit = (values: IFormValues, formActions: FormikActions<IFormValues>) => {
    values.offers.forEach((offer, index) => {

      const formatUrl = (url: string) => {
        const prefix = 'https://';
        const regExp = /^https?:\/\//;
        if (url.match(regExp)) {
          return url;
        }
        return `${prefix}${url}`;
      };

      const push = {
        push_task: {
          offer,
          title: values.title,
          message: values.message,
          push_type: 'show_content',
          send_at: values.send_at ? values.send_at : null,
          content_type: values.resource_type === 'item' ? values.item_type : values.resource_type,
          content_id: values.resource_type === 'url' ? null : values.resource_link,
          content_url: values.resource_type === 'url' ? formatUrl(values.resource_link) : null,
          user_ids: values.user_ids ? values.user_ids : [],
          user_groups: values.user_groups ? values.user_groups : [],
          send_to_all: !values.user_groups && !values.user_ids,
        },
      };
      crudMutate({
        formActions,
        mutation: createPushTaskMutation,
        variables: push,
        redirect: values.offers.length === (index + 1) ? '/pushes' : undefined,
        check: Boolean(Object.keys(push).length),
      });
    });
  };

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = () => {
    const { t } = this.props;
    return Yup.object().shape(Object.assign({
      title: Yup.string().required(t('forms:required_field', { field: t('title') })),
      message: Yup.string().required(t('forms:required_field', { field: t('message') })),
      offers: Yup.array().required(t('forms:required_field', { field: t('offers') })),
      resource_link: Yup.string().required(t('forms:required_field', { field: t('resource_link') })),
    }));
  };

  buildInitialFormData = () => {
    const { data } = this.props;
    return {
      title: field(data, 'title', ''),
      message: field(data, 'message', ''),
      resource_type: field(data, 'resource_type', 'category'),
      offers: data ? data.offers.map(o => o.id) : LocalStorage.get(PUSH_OFFERS),
    };
  };

  render() {
    return (
      <Formik
        validationSchema={this.validationSchema()}
        enableReinitialize
        initialValues={this.buildInitialFormData()}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation('pushes')(Form);
