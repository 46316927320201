import { IOfferInput } from '../types';

function makeInterface() {
  return {
    title_showcase: '',
    title_my_collection: '',
    title_documents: '',
    title_my_documents: '',
  };
}

function makeFeatures() {
  return {
    corporate: false,
    in_app_purchase: false,
    books: false,
    content_table: false,
    documents: false,
    banners: false,
    badges: false,
    wishlist: false,
    comments: false,
    push_notification: false,
    local_notification: false,
    required_login: false,
    socials: false,
    pins: false,
    user_groups: false,
    analytic: false,
    app_rate: false,
  };
}

function makeIosBranding() {
  return {
    title: '',
    splash_title: '',
    splash_subtitle: '',
    primary_color: '',
    bar_color: '',
    selected_button_color: '',
    unselected_button_color: '',
    background_splash_color: '',
    splash_text_color: '',
    splash_subtitle_color: '',
  };
}

function makeAndroidBranding() {
  return {
    ...makeIosBranding(),
    primary_dark_color: '',
    primary_light_color: '',
    accent_color: '',
    accent_dark_color: '',
    text_primary_color: '',
    secondary_text_color: '',
    icons_color: '',
    divider_color: '',
    background_light_color: '',
  };
}

export default (offer: IOfferInput) => {
  offer.settingsBranding = {
    color_main: '',
    color_unpressed: '',
    color_pressed: '',
    color_selected: '',
    color_banner_arrow: '',
    color_banner_bar: '',
    custom_titles: {
      en: {
        title_registration: '',
        title_login: '',
        title_latest: '',
        title_most_readable: '',
        title_books: '',
        title_hybrids: '',
        title_documents: '',
        title_collections: '',
        title_videos: '',
        title_audiobooks: '',
      },
      ru: {
        title_registration: '',
        title_login: '',
        title_latest: '',
        title_most_readable: '',
        title_books: '',
        title_hybrids: '',
        title_documents: '',
        title_collections: '',
        title_videos: '',
        title_audiobooks: '',
      },
    },
  };

  offer.settingsRegistration = {
    registration_enabled: false,
    users_import_enabled: false,
    registration_unlimited: true,
    registration_welcome_mail: true,
    send_b2b_registration_mail: false,
    users_allowed: 0,
    groups_enabled: true,
    group_required: true,
    confirmation_required: true,
    pins_enabled: true,
    registration_by_email_enabled: true,
    alpina_lab_registration_enabled: false,
    ip_mask_filter: [],
    email_mask_filter: [],
  };

  offer.settingsContent = {
    books_enabled: true,
    books_max_size: null,
    books_download_limit: null,
    books_download_epub_enabled: true,
    books_download_pdf_enabled: true,
    books_download_mobi_enabled: true,
    hybrids_enabled: true,
    hybrids_max_count: null,
    hybrids_max_size: null,
    documents_enabled: true,
    documents_max_count: 100,
    documents_max_size: 20971520,
    collections_enabled: true,
    collections_max_count: null,
    videos_enabled: true,
    videos_max_count: null,
    videos_max_size: 209715200,
    audiobooks_enabled: true,
    courses_enabled: true,
    audiobooks_max_count: null,
    audiobooks_max_size: null,
    games_enabled: false,
    tests_enabled: false,
    events_enabled: false,
  };

  offer.settingsBuildAndroid = {
    bundle_id: '',
    interface: makeInterface(),
    features: makeFeatures(),
    branding: makeAndroidBranding(),
    externals: [],
    socials: [],
    analytics: [],
  };

  offer.settingsBuildIos = {
    bundle_id: '',
    interface: makeInterface(),
    features: makeFeatures(),
    branding: makeIosBranding(),
    externals: [],
    socials: [],
    analytics: [],
  };
};
