import React from 'react';
import moment from 'moment';
import { Redirect } from 'react-router';
import { withTranslation, WithTranslation } from 'react-i18next';
import FormFrame from 'features/ui/FormFrame';
import { FormikProps } from "formik";
import { makeUsersSubscriptionUrl } from 'features/userSubscriptions/lib/url';
import FormRow from 'features/ui/FormRow';
import DatePicker from 'features/ui/DatePicker';
import { Moment } from 'features/types';
import UserSelect from 'features/users/lib/UserSelect';
import SubscriptionsSelect from 'features/subscriptions/lib/SubscriptionsSelect';
import { IUserSubscription } from "features/userSubscriptions/types";

interface IUserSubscriptionFormProps extends FormikProps<IUserSubscription>, WithTranslation {
  userSubscription?: IUserSubscription;
}

class BaseUserSubscriptionForm extends React.Component<IUserSubscriptionFormProps, any> {
  onChangeDate = (date: Moment | null, field: string) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, date ? moment(date).format('YYYY-MM-DD') : '');
  };

  handleChange = (value, filed) => {
    const { setFieldValue } = this.props;
    setFieldValue(filed, value);
  }

  render() {
    const {
      userSubscription,
      handleBlur,
      values,
      t,
    } = this.props;

    if (userSubscription && userSubscription.id && values.cost > 0) {
      return <Redirect to={`/user-subscriptions/${userSubscription.id}`} />;
    }

    return (
      <FormFrame
        id={userSubscription ? userSubscription.id : undefined}
        cancelLink={makeUsersSubscriptionUrl()}
        {...this.props}
      >
        <FormRow label={t('user')} id="user" required>
          <UserSelect
            id="user"
            name="user"
            withOfferId
            value={values.user}
            onChange={(value) => this.handleChange(value, 'user')}
            onBlur={handleBlur}
          />
        </FormRow>
        <FormRow label={t('subscription')} id="subscription" required>
          <SubscriptionsSelect
            id="subscription"
            name="subscription"
            isMulti={false}
            value={values.subscription}
            onChange={(value) => this.handleChange(value, 'subscription')}
            onBlur={handleBlur}
          />
        </FormRow>
        <FormRow label={t('from')} id="from" required>
          <DatePicker
            id="from"
            value={Date.parse(values.from)}
            onChange={(date: Moment | null) => this.onChangeDate(date, 'from')}
          />
        </FormRow>
        <FormRow label={t('to')} id="to" required>
          <DatePicker
            id="to"
            value={Date.parse(values.to)}
            onChange={(date: Moment | null) => this.onChangeDate(date, 'to')}
          />
        </FormRow>
      </FormFrame>
    );
  }
}

export default withTranslation('user_subscriptions')(BaseUserSubscriptionForm);
