import React, { Component } from 'react';
import { match as IMatch } from 'react-router';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  flow,
  get,
} from 'lodash';
import { History } from 'history';
import { graphqlById } from 'features/graphql/helpers';
import { AccessControl } from 'features/acl';
import LinkBlock from 'features/ui/LinkBlock';
import Waiter from 'features/ui/Waiter';
import getOfferQuery from '../queries/getOffer.gql';
import TabEdit from '../TabEdit';
import TabItems from '../TabItems';
import TabEditBranding from '../TabEditBranding';
import TabEditContent from '../TabEditContent';
import TabEditRegistration from '../TabEditRegistration';
import TabEditBuild from '../TabEditBuild';
import TabEditNotifications from '../TabEditNotifications';
import TabOthers from '../TabOthers';
import Tabs from '../lib/Tabs';
import { makeOfferUrlTo } from '../lib/url';
import { IOffer } from '../types';
import { VIEW_TABS } from '../consts';

interface IPageEditProps extends WithTranslation {
  data: IOffer | null;
  history: History;
  isLoading: boolean;
  match: IMatch<any>;
  refetch: (args?: any) => Promise<any>;
}

interface IPageEditState {
  activeTab: string;
}

class PageEdit extends Component<IPageEditProps, IPageEditState> {
  renderTab(activeTab: string) {
    const {
      isLoading,
      data,
      refetch,
    } = this.props;
    if (!data) {
      return null;
    }
    const linkBlock = <LinkBlock viewLink={makeOfferUrlTo(data, 'view')} />;
    switch (activeTab) {
      case VIEW_TABS.offer: return (
        <AccessControl permission="offers:offer:edit">
          {linkBlock}
          <TabEdit isLoading={isLoading} offer={data} />
        </AccessControl>
      );
      case VIEW_TABS.branding: return (
        <AccessControl permission="offers:branding:edit">
          {linkBlock}
          <TabEditBranding offer={data} />
        </AccessControl>
      );
      case VIEW_TABS.items: return (
        <AccessControl permission="offers:items:edit">
          {linkBlock}
          <TabItems offer={data} />
        </AccessControl>
      );
      case VIEW_TABS.registration: return (
        <AccessControl permission="offers:registration:edit">
          {linkBlock}
          <TabEditRegistration offer={data} />
        </AccessControl>
      );
      case VIEW_TABS.content: return (
        <AccessControl permission="offers:content:edit">
          {linkBlock}
          <TabEditContent offer={data} />
        </AccessControl>
      );
      case VIEW_TABS.build: return (
        <AccessControl permission="offers:build:edit">
          {linkBlock}
          <TabEditBuild offer={data} />
        </AccessControl>
      );
      case VIEW_TABS.notifications: return (
        <AccessControl permission="offers:notifications:edit">
          {linkBlock}
          <TabEditNotifications offer={data} refetch={refetch} />
        </AccessControl>
      );
      case VIEW_TABS.others: return (
        <AccessControl permission="offers:others:edit">
          {linkBlock}
          <TabOthers offer={data} refetch={refetch} />
        </AccessControl>
      );
    }
    return null;
  }

  render() {
    const {
      isLoading,
      data,
      match,
      history,
    } = this.props;
    return (
      <Waiter isLoading={isLoading} data={data}>
        <Tabs
          history={history}
          match={match}
          offerId={get(data, 'id', '')}
          offerName={get(data, 'name', '')}
        >
          {activeTab => this.renderTab(activeTab)}
        </Tabs>
      </Waiter>
    );
  }
}

export default flow([
  graphqlById({
    query: getOfferQuery,
    queryType: 'OfferQuery',
  }),
  withTranslation('offers'),
])(PageEdit);
