import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import FormRow from 'features/ui/FormRow';
import TranslationsWidget from 'features/offers/lib/LanguagesTable/TranslationsWidget';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import { formFieldsBuilder } from 'features/ui/FormFields';
import { IFetchedTranslation } from 'features/offers/lib/LanguagesTable/types';
import Images from './Images';
import {
  IOffer,
  IOfferSettingsBrandingFormValues,
} from '../types';

interface IFormProps extends FormikProps<IOfferSettingsBrandingFormValues>, WithTranslation {
  id?: string;
  offer?: IOffer;
}

const Brand = formFieldsBuilder({
  namePrefix: (name: string) => `${name}`,
  labelPrefix: (name: string) => `settingsBranding.${name.replace(/custom_titles\.(ru|en)\./, '')}`,
});

class BaseForm extends React.Component<IFormProps> {
  renderCustomTitles(lang: string) {
    const { t } = this.props;
    const form = this.props;
    return (
      <React.Fragment>
        <h3>{t(`settingsBranding.custom_titles_${lang}`)}</h3>
        <Brand.Input name={`custom_titles.${lang}.title_registration`} type="text" form={form} />
        <Brand.MarkDown name={`custom_titles.${lang}.title_login`} type="text" form={form} />
        <Brand.Input name={`custom_titles.${lang}.title_latest`} type="text" form={form} />
        <Brand.Input name={`custom_titles.${lang}.title_most_readable`} type="text" form={form} />
        <Brand.Input name={`custom_titles.${lang}.title_collections`} type="text" form={form} />
        <Brand.Input name={`custom_titles.${lang}.title_videos`} type="text" form={form} />
        <Brand.Input name={`custom_titles.${lang}.title_audiobooks`} type="text" form={form} />
      </React.Fragment>
    );
  }

  onChangeEntity = (field: string) => (ids: number[]) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, ids);
  };

  render() {
    const {
      id,
      offer,
      t,
      values,
    } = this.props;
    const form = this.props;
    const colorExample = t('forms:example', { text: '#3a4b5c' });
    const formattedErrors = values.translations.map((err: IFetchedTranslation) => ({
      id: err.id,
      translation: err.translation.id,
      language: err.language.id,
      value: err.value,
    }));
    return (
      <FormFrame
        id={id}
        cancelLink="/offers"
        {...this.props}
      >
        <Brand.Color name="color_main" text={colorExample} form={form} />
        <Brand.Color name="color_unpressed" text={colorExample} form={form} />
        <Brand.Color name="color_pressed" text={colorExample} form={form} />
        <Brand.Color name="color_selected" text={colorExample} form={form} />
        <Brand.Color name="color_banner_arrow" text={colorExample} form={form} />
        <Brand.Color name="color_banner_bar" text={colorExample} form={form} />
        <Images
          components={Brand}
          offer={offer}
          form={form}
        />
        <hr />
        <Brand.Input name="support_email" type="text" form={form} />
        <FormRow key="translations" label="Кастомные ошибки" id="translations">
          <TranslationsWidget
            translations={formattedErrors}
            onChange={this.onChangeEntity('translations')}
          />
        </FormRow>
        <hr />
        {this.renderCustomTitles('ru')}
        <hr />
        {this.renderCustomTitles('en')}
      </FormFrame>
    );
  }
}

export default withTranslation('offers')(BaseForm);
