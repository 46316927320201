import { graphqlById } from 'features/graphql/helpers';
import React, { Component } from 'react';
import ReactTable, { Column } from 'react-table';
import moment from 'moment';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import {
  capitalize,
  flow,
  get,
} from 'lodash';
import { History } from 'history';
import { makeLinkListValue } from 'lib/makeLinkListValue';
import { AccessControl } from 'features/acl';
import { makeCategoryUrl } from 'features/categories/lib/url';
import { makeBannerUrl } from 'features/banners/lib/url';
import { match as IMatch } from 'react-router';
import RenderIf from 'features/ui/RenderIf';
import LinkBlock from 'features/ui/LinkBlock';
import OnOffIndicator from 'features/ui/OnOffIndicator';
import NotSet from 'features/ui/NotSet';
import Waiter from 'features/ui/Waiter';
import {
  InfoRow,
  InfoRowBytes,
  InfoRowColor,
  InfoRowCost,
  InfoRowImage,
  InfoRowLink,
  InfoRowLinkList,
  InfoRowNotSet,
  InfoRowSwitch,
  InfoRowText,
} from 'features/ui/InfoRow';
import ListOfUsersByRole from '../lib/ListOfUsersByRole';
import { makeOfferUrlTo } from '../lib/url';
import Tabs from '../lib/Tabs';
import TabOthers from '../TabOthers';
import TabViewItems from '../TabViewItems';

import getOfferQuery from '../queries/getOffer.gql';
import getUsersForNotificationQuery from '../queries/getUsersForNotificationQuery.gql';
import getNotifications from '../queries/getNotifications.gql';
import styles from './styles.module.scss';

import { VIEW_TABS } from '../consts';

import {
  IOffer,
  IOfferSettingsBrandingCustomTitlesLang,
} from '../types';
import { ITranslation } from 'features/offers/lib/LanguagesTable/types';
import { client, gql } from 'features/graphql';
import { makeUserUrl } from 'features/users/lib/url';

interface IPageViewProps extends WithTranslation {
  data: IOffer | null;
  history: History;
  isLoading: boolean;
  offer: IOffer;
  match: IMatch<any>;
}

interface IPageViewState {
  activeTab: string;
  languages: [];
  notificationsData: any[];
  notifications: any[];
}

const PlatformIcon = ({ platform }: { platform: string }) => {
  if (platform === 'android') {
    return <i className="fa fa-android fa-lg" />;
  }
  if (platform === 'ios') {
    return <i className="fa fa-apple fa-lg" />;
  }
  return <NotSet />;
};

class PageView extends Component<IPageViewProps, IPageViewState> {
  static COLUMNS = [
    {
      Header: 'ID',
      accessor: 'id',
      className: 'text-right',
      maxWidth: 100,
    },
    {
      Header: 'Код ошибки',
      id: 'translation',
      accessor: (data: ITranslation) => {
        const { translation } = data;
        if (translation) {
          return `${translation.id}: ${translation.key}`;
        }
        return '';
      },
      maxWidth: 200,
    },
    {
      Header: 'Язык',
      id: 'language',
      accessor: (data: ITranslation) => {
        const { language } = data;
        if (language) {
          return `${language.name}`;
        }
        return '';
      },
      maxWidth: 100,
    },
    {
      Header: 'Текст',
      id: 'value',
      accessor: (data: ITranslation) => {
        const { value } = data;
        if (value) {
          return value;
        }
        return '';
      },
      maxWidth: 100,
    },
  ];

  private _buildColumnsExternals: Column[];
  private _buildColumnsSocials: Column[];
  private _buildColumnsAnalytics: Column[];

  constructor(props: IPageViewProps) {
    super(props);
    const { t } = props;
    this.state = {
      languages: [],
      notificationsData: [],
      notifications: [],
    };
    const platform = {
      accessor: 'platform',
      className: 'text-center',
      maxWidth: 50,
      Cell: ({ row }: { row: { platform: string } }) => <PlatformIcon platform={row.platform} />,
    };
    const enabledCell = ({ row }: { row: { enabled: boolean } }) => <OnOffIndicator value={row.enabled} />;
    this._buildColumnsExternals = [
      platform,
      { accessor: 'type', Header: t('build_externals_type') },
      { accessor: 'key', Header: t('build_externals_key') },
      { accessor: 'secret', Header: t('build_externals_secret') },
      { accessor: 'domain', Header: t('build_externals_domain') },
      { accessor: 'enabled', Header: t('build_externals_enabled'), Cell: enabledCell },
    ];
    this._buildColumnsSocials = [
      platform,
      { accessor: 'key', Header: t('build_socials_key') },
      { accessor: 'type', Header: t('build_socials_type') },
      { accessor: 'enabled', Header: t('build_socials_enabled'), Cell: enabledCell },
    ];
    this._buildColumnsAnalytics = [
      platform,
      { accessor: 'key', Header: t('build_analytics_key') },
      { accessor: 'type', Header: t('build_analytics_type') },
      { accessor: 'enabled', Header: t('build_analytics_enabled'), Cell: enabledCell },
    ];
  }

  componentDidUpdate(prevProps: IPageViewProps) {
    if (prevProps.data !== this.props.data) {
      const { data: { id } } = this.props;
      client.query({
        query: getNotifications,
        variables: {
          pageNum: 1,
          perPage: 100,
        },
      }).then(res => res.data.NotificationRuleQuery.items.filter(item => item.key.includes('mails_hr')))
        .then((notificationsList) => {
          Promise.all(notificationsList.map((notification: any) => client.query({
            query: getUsersForNotificationQuery,
            variables: {
              filterByFields: {
                rule_id: {
                  operator: '=',
                  value: notification.id,
                },
                offer_id: {
                  operator: '=',
                  value: id.toString(),
                },
              },
            },
          }).then(result => ({
            [notification.key]: result.data.NotificationRuleUsersByOfferQuery.items,
          })))).then((data) => {
            this.setState({ notificationsData: data, notifications: notificationsList });
          });
        });
    }
  }

  renderOffer(offer: IOffer) {
    const { t } = this.props;
    const expireDate = offer.expire_date && moment(offer.expire_date).format('ll');
    const categoriesLinkListValue = makeLinkListValue(offer.categories, 'name', makeCategoryUrl, 'view');
    const bannersLinkListValue = makeLinkListValue(offer.banners, 'name', makeBannerUrl, 'view');
    const defaultLanguage = offer.defaultLanguage ? offer.defaultLanguage.name : t('dict:not_selected');
    return (
      <React.Fragment>
        <InfoRowText label="ID" value={offer.id} />
        <InfoRowText label={t('offer_name')} value={offer.name} />
        <InfoRowText label={t('offer_prefix')} value={offer.prefix} />
        <InfoRowText label={t('base_domain_text')} value={offer.base_domain} />
        <InfoRowText label={t('offer_languages')} value={offer.languages.map(ln => capitalize(ln.name)).join(', ')} />
        <InfoRowText label={t('offer_default_language')} value={capitalize(defaultLanguage)} />
        <InfoRowText label={t('offer_default_mail_language')} value={capitalize(offer.mailLanguage.name)} />
        <InfoRowSwitch label={t('offer_is_trial')} value={offer.is_trial} />
        <InfoRowLink
          label={t('offer_url_to_hr')}
          value={`https://hr.${offer.base_domain}/auth/brand/${offer.prefix}`}
          target="_blank"
        />
        <InfoRowLink
          label={t('offer_url_to_prod')}
          value={`https://${offer.prefix}.${offer.base_domain}`}
          target="_blank"
        />
        <InfoRowText label={t('offer_expire_date')} value={expireDate} />
        <InfoRowText label={t('offer_contacts')} value={offer.contacts} />
        <InfoRowCost label={t('offer_cost')} value={offer.cost} />
        <InfoRowText label={t('offer_business_model')} value={offer.business_model && t<string>(offer.business_model)} />
        <InfoRowText label={t('offer_grossing_period')} value={offer.grossing_period} />
        <hr />
        <InfoRowText label={t('offer_ios_prefix')} value={offer.ios_prefix} />
        <InfoRowLink
          label={t('offer_ios_link')}
          value={offer.ios_link}
          target="_blank"
        />
        <hr />
        <InfoRowText label={t('offer_android_prefix')} value={offer.android_prefix} />
        <InfoRowLink
          label={t('offer_android_link')}
          value={offer.android_link}
          target="_blank"
        />
        <hr />
        <InfoRowSwitch label={t('offer_singleapp_ios_enabled')} value={offer.singleapp_ios_enabled} />
        <InfoRowSwitch label={t('offer_singleapp_android_enabled')} value={offer.singleapp_android_enabled} />
        <InfoRowText label={t('offer_singleapp_title')} value={offer.singleapp_title} />
        <hr />
        <InfoRowSwitch label={t('offer_enable_banners')} value={offer.enable_banners} />
        <InfoRowSwitch label={t('offer_enable_pushes')} value={offer.enable_pushes} />
        <InfoRowSwitch label={t('offer_enable_external_link')} value={offer.enable_external_link} />
        <InfoRowSwitch label={t('offer_enable_comments')} value={offer.enable_comments} />
        <InfoRowSwitch label={t('offer_new_items_by_default')} value={offer.new_items_by_default} />
        <hr />
        <InfoRowLinkList label={t('offer_categories')} value={categoriesLinkListValue} />
        <InfoRowLinkList label={t('offer_banners')} value={bannersLinkListValue} />
        <InfoRow label={t('offer_admin_list')}>
          <ListOfUsersByRole role="hr" offerId={offer.id} showOnlyActive />
        </InfoRow>
        <hr />
        <InfoRowText label={t('dict:updated')} value={moment(offer.updated_at).format('lll')} />
        <InfoRowText label={t('dict:created')} value={moment(offer.created_at).format('lll')} />
      </React.Fragment>
    );
  }

  renderBrandingCustomTitles(titles: IOfferSettingsBrandingCustomTitlesLang) {
    const { t } = this.props;
    const prefix = 'offers:settingsBranding';
    return (
      <React.Fragment>
        <InfoRowText label={t(`${prefix}:title_registration`)} value={titles.title_registration} />
        <InfoRowText label={t(`${prefix}:title_login`)} value={titles.title_login} />
        <InfoRowText label={t(`${prefix}:title_latest`)} value={titles.title_latest} />
        <InfoRowText label={t(`${prefix}:title_most_readable`)} value={titles.title_most_readable} />
        <InfoRowText label={t(`${prefix}:title_books`)} value={titles.title_books} />
        <InfoRowText label={t(`${prefix}:title_hybrids`)} value={titles.title_hybrids} />
        <InfoRowText label={t(`${prefix}:title_collections`)} value={titles.title_collections} />
        <InfoRowText label={t(`${prefix}:title_videos`)} value={titles.title_videos} />
        <InfoRowText label={t(`${prefix}:title_audiobooks`)} value={titles.title_audiobooks} />
      </React.Fragment>
    );
  }

  renderBranding(offer: IOffer) {
    const { t } = this.props;
    const brand = offer.settingsBranding;
    const prefix = 'offers:settingsBranding';
    return (
      <React.Fragment>
        <InfoRowColor label={t(`${prefix}:color_main`)} value={brand.color_main} />
        <InfoRowColor label={t(`${prefix}:color_unpressed`)} value={brand.color_unpressed} />
        <InfoRowColor label={t(`${prefix}:color_pressed`)} value={brand.color_pressed} />
        <InfoRowColor label={t(`${prefix}:color_selected`)} value={brand.color_selected} />
        <InfoRowColor label={t(`${prefix}:color_banner_arrow`)} value={brand.color_banner_arrow} />
        <InfoRowColor label={t(`${prefix}:color_banner_bar`)} value={brand.color_banner_bar} />
        <hr />
        <InfoRowImage label={t(`${prefix}:logoTop`)} value={get(brand, 'logoTop.path', null)} width={340}/>
        <InfoRowImage label={t(`${prefix}:logoBottom`)} value={get(brand, 'logoBottom.path', null)} width={364}/>
        <InfoRowImage label={t(`${prefix}:appIcon`)} value={get(brand, 'appIcon.path', null)} width={100}/>
        <InfoRowImage label={t(`${prefix}:splashLogoTop`)} value={get(brand, 'splashLogoTop.path', null)} width={318}/>
        <InfoRowImage label={t(`${prefix}:splashLogoBottom`)} value={get(brand, 'splashLogoBottom.path', null)} width={318}/>
        <hr />
        <InfoRowText label={t(`${prefix}:support_email`)} value={get(brand, 'support_email', 'support@alpina.help')} />
        {this.renderTranslations(offer)}
        <hr />
        <h3>{t(`${prefix}:custom_titles_ru`)}</h3>
        <br />
        {this.renderBrandingCustomTitles(brand.custom_titles.ru)}
        <hr />
        <h3>{t(`${prefix}:custom_titles_en`)}</h3>
        <br />
        {this.renderBrandingCustomTitles(brand.custom_titles.en)}
      </React.Fragment>
    );
  }

  renderRegistration(offer: IOffer) {
    const { t } = this.props;
    const reg = offer.settingsRegistration;
    let usersAllowed = null;
    let labUsersAllowed = null;
    // reg_alpina_lab_users_allowed
    if (!reg.registration_unlimited) {
      usersAllowed = <InfoRowText label={t('reg_users_allowed')} value={reg.users_allowed} />;
    }
    if (!reg.registration_unlimited) {
      labUsersAllowed = <InfoRowText label={t('reg_alpina_lab_users_allowed')} value={reg.alpina_lab_users_allowed} />;
    }
    return (
      <React.Fragment>
        <InfoRowSwitch label={t('reg_users_import_enabled')} value={reg.users_import_enabled} />
        <InfoRowSwitch label={t('reg_registration_welcome_mail')} value={reg.registration_welcome_mail} />
        <InfoRowSwitch label={t('reg_send_b2b_registration_mail')} value={reg.send_b2b_registration_mail} />
        <InfoRowSwitch label={t('reg_groups_enabled')} value={reg.groups_enabled} />
        <InfoRowSwitch label={t('reg_group_required')} value={reg.group_required} />
        <InfoRowSwitch label={t('reg_confirmation_required')} value={reg.confirmation_required} />
        <InfoRowSwitch label={t('reg_pins_enabled')} value={reg.pins_enabled} />
        <InfoRowSwitch label={t('reg_registration_by_email_enabled')} value={reg.registration_by_email_enabled} />
        <InfoRowSwitch label={t('reg_alpina_lab_enabled')} value={reg.alpina_lab_enabled} />
        <InfoRowSwitch label={t('reg_registration_enabled')} value={reg.registration_enabled} />
        <InfoRowSwitch label={t('req_alpina_lab_registration_enabled')} value={reg.alpina_lab_registration_enabled} />
        <InfoRowText label={t('offer_prefix_registration_link')} value={`https://login.alpinadigital.ru/offer/${offer.prefix}`} />
        <hr />
        <InfoRowText label={t('reg_default_users_ttl')} value={reg.default_users_ttl} />
        <InfoRowText label={t('reg_users_registered')} value={reg.users_registered} />
        <InfoRowSwitch label={t('reg_password_change_enabled')} value={reg.registration_unlimited} />
        <InfoRowSwitch label={t('reg_registration_unlimited')} value={reg.registration_unlimited} />
        {usersAllowed}
        {labUsersAllowed}
        <hr />
        <InfoRowText label={t('reg_ip_mask_filter')} value={reg.ip_mask_filter.join(' ')} />
        <InfoRowText label={t('reg_email_mask_filter')} value={reg.email_mask_filter.join(' ')} />
      </React.Fragment>
    );
  }

  renderContent(offer: IOffer) {
    const { t } = this.props;
    const content = offer.settingsContent;
    return (
      <React.Fragment>
        <InfoRowText label={t('content_books_download_limit')} value={content.books_download_limit} />
        <InfoRowSwitch label={t('content_events_calendar_enabled')} value={content.events_calendar_enabled} />
        <hr />
        <InfoRowSwitch label={t('content_latest_enabled')} value={content.latest_enabled} />
        <InfoRowSwitch label={t('content_popular_enabled')} value={content.popular_enabled} />
        <hr />
        <InfoRowSwitch label={t('content_books_enabled')} value={content.books_enabled} />
        <InfoRowBytes label={t('content_books_max_size')} value={content.books_max_size} />
        <InfoRowSwitch label={t('content_books_download_epub_enabled')} value={content.books_download_epub_enabled} />
        <InfoRowSwitch label={t('content_books_download_pdf_enabled')} value={content.books_download_pdf_enabled} />
        <InfoRowSwitch label={t('content_books_download_mobi_enabled')} value={content.books_download_mobi_enabled} />
        <hr />
        <InfoRowSwitch label={t('content_hybrids_enabled')} value={content.hybrids_enabled} />
        <InfoRowText label={t('content_hybrids_max_count')} value={content.hybrids_max_count} />
        <InfoRowBytes label={t('content_hybrids_max_size')} value={content.hybrids_max_size} />
        <hr />
        <InfoRowSwitch label={t('content_documents_enabled')} value={content.documents_enabled} />
        <InfoRowText label={t('content_documents_max_count')} value={content.documents_max_count} />
        <InfoRowBytes label={t('content_documents_max_size')} value={content.documents_max_size} />
        <hr />
        <InfoRowSwitch label={t('content_collections_enabled')} value={content.collections_enabled} />
        <InfoRowText label={t('content_collections_max_count')} value={content.collections_max_count} />
        <hr />
        <InfoRowSwitch label={t('content_videos_enabled')} value={content.videos_enabled} />
        <InfoRowText label={t('content_videos_max_count')} value={content.videos_max_count} />
        <InfoRowBytes label={t('content_videos_max_size')} value={content.videos_max_size} />
        <hr />
        <InfoRowSwitch label={t('content_courses_enabled')} value={content.courses_enabled} />
        <InfoRowSwitch label={t('content_articles_enabled')} value={content.articles_enabled} />
        <hr />
        <InfoRowSwitch label={t('content_audiobooks_enabled')} value={content.audiobooks_enabled} />
        <InfoRowText label={t('content_audiobooks_max_count')} value={content.audiobooks_max_count} />
        <InfoRowBytes label={t('content_audiobooks_max_size')} value={content.audiobooks_max_size} />
        <hr />
        <InfoRowSwitch label={t('content_games_enabled')} value={content.games_enabled} />
        <InfoRowSwitch label={t('content_tests_enabled')} value={content.tests_enabled} />
        <hr />
        <InfoRowSwitch label={t('content_events_enabled')} value={content.events_enabled} />

      </React.Fragment>
    );
  }

  renderBuildField(
    offer: IOffer,
    name: string,
    androidComponent: React.ReactNode,
    iosComponent: React.ReactNode | null,
    wrapperClass: string = '',
    iconsColor: string[] = ['black', 'black'],
  ) {
    const { t } = this.props;
    const androidValue = get(offer, `settingsBuildAndroid.${name}`, '');
    const iosValue = get(offer, `settingsBuildIos.${name}`, '');
    let androidComponentRenderer = androidComponent;
    let iosComponentRenderer = iosComponent;
    if (androidValue === undefined || androidValue === null || androidValue === '') {
      androidComponentRenderer = <NotSet />;
    }
    if (iosValue === undefined || iosValue === null || iosValue === '') {
      iosComponentRenderer = <NotSet />;
    }
    return (
      <InfoRow label={t(`build_${name}`.replace('.', '_'))}>
        <div className={styles.groupOfPlatformValues}>
          <span className={wrapperClass}>
            <i
              style={{ color: iconsColor[0] }}
              className="fa fa-android fa-lg"
            />
            {androidComponentRenderer}
          </span>
          <RenderIf condition={iosComponent !== null}>
            <span className={wrapperClass}>
              <i
                style={{ color: iconsColor[1] }}
                className="fa fa-apple fa-lg"
              />
              {iosComponentRenderer}
            </span>
          </RenderIf>
        </div>
      </InfoRow>
    );
  }

  renderBuildFieldText(offer: IOffer, name: string) {
    return this.renderBuildField(
      offer,
      name,
      <span>{get(offer, `settingsBuildAndroid.${name}`, '')}</span>,
      <span>{get(offer, `settingsBuildIos.${name}`, '')}</span>,
      styles.text,
    );
  }

  renderTranslations(offer: IOffer) {
    const { translations } = offer;
    if (translations === null || translations === undefined || translations.length === 0) {
      return <InfoRowNotSet label="Кастомные ошибки" />;
    }
    return (
      <InfoRow label="Кастомные ошибки">
        <ReactTable
          columns={PageView.COLUMNS}
          data={translations}
          minRows={0}
          filterable={false}
          showPagination={false}
        />
      </InfoRow>
    );
  }

  renderBuildFieldSwitch(offer: IOffer, name: string) {
    return this.renderBuildField(
      offer,
      name,
      <OnOffIndicator value={get(offer, `settingsBuildAndroid.${name}`, '') as boolean} />,
      <OnOffIndicator value={get(offer, `settingsBuildIos.${name}`, '') as boolean} />,
      styles.switch,
    );
  }

  renderBuildFieldColor(offer: IOffer, name: string, shouldRenderIos: boolean = true) {
    const androidColor = get(offer, `settingsBuildAndroid.${name}`, '') as string;
    const iosColor = get(offer, `settingsBuildIos.${name}`, '') as string;
    return this.renderBuildField(
      offer,
      name,
      <span>{androidColor}</span>,
      shouldRenderIos ? <span>{iosColor}</span> : null,
      styles.color,
      [androidColor, iosColor],
    );
  }

  renderTable(columns: any[], data: any[] | null | undefined, label: string) {
    const { t } = this.props;
    if (data === null || data === undefined || data.length === 0) {
      return <InfoRowNotSet label={t(label)} />;
    }
    return (
      <InfoRow label={t(label)}>
        <ReactTable
          columns={columns}
          data={data}
          minRows={0}
          filterable={false}
          showPagination={false}
        />
      </InfoRow>
    );
  }

  joinBuildTableData(offer: IOffer, field: string) {
    return [
      ...(offer.settingsBuildAndroid[field] as object[]).map(data => ({ ...data, platform: 'android' })),
      ...(offer.settingsBuildIos[field] as object[]).map(data => ({ ...data, platform: 'ios' })),
    ];
  }

  renderBuild(offer: IOffer) {
    const externals = this.joinBuildTableData(offer, 'externals');
    const socials = this.joinBuildTableData(offer, 'socials');
    const analytics = this.joinBuildTableData(offer, 'analytics');
    return (
      <React.Fragment>
        {this.renderBuildFieldText(offer, 'bundle_id')}
        <hr />
        {this.renderBuildFieldSwitch(offer, 'features.corporate')}
        {this.renderBuildFieldSwitch(offer, 'features.in_app_purchase')}
        {this.renderBuildFieldSwitch(offer, 'features.books')}
        {this.renderBuildFieldSwitch(offer, 'features.content_table')}
        {this.renderBuildFieldSwitch(offer, 'features.documents')}
        {this.renderBuildFieldSwitch(offer, 'features.banners')}
        {this.renderBuildFieldSwitch(offer, 'features.badges')}
        {this.renderBuildFieldSwitch(offer, 'features.wishlist')}
        {this.renderBuildFieldSwitch(offer, 'features.comments')}
        {this.renderBuildFieldSwitch(offer, 'features.push_notification')}
        {this.renderBuildFieldSwitch(offer, 'features.local_notification')}
        {this.renderBuildFieldSwitch(offer, 'features.required_login')}
        {this.renderBuildFieldSwitch(offer, 'features.socials')}
        {this.renderBuildFieldSwitch(offer, 'features.pins')}
        {this.renderBuildFieldSwitch(offer, 'features.user_groups')}
        {this.renderBuildFieldSwitch(offer, 'features.analytic')}
        {this.renderBuildFieldSwitch(offer, 'features.app_rate')}
        <hr />
        {this.renderBuildFieldText(offer, 'interface.title_showcase')}
        {this.renderBuildFieldText(offer, 'interface.title_my_collection')}
        {this.renderBuildFieldText(offer, 'interface.title_my_documents')}
        <hr />
        {this.renderBuildFieldText(offer, 'branding.title')}
        {this.renderBuildFieldText(offer, 'branding.splash_title')}
        {this.renderBuildFieldText(offer, 'branding.splash_subtitle')}
        {this.renderBuildFieldColor(offer, 'branding.primary_color')}
        {this.renderBuildFieldColor(offer, 'branding.bar_color')}
        {this.renderBuildFieldColor(offer, 'branding.selected_button_color')}
        {this.renderBuildFieldColor(offer, 'branding.unselected_button_color')}
        {this.renderBuildFieldColor(offer, 'branding.background_splash_color')}
        {this.renderBuildFieldColor(offer, 'branding.splash_text_color')}
        {this.renderBuildFieldColor(offer, 'branding.splash_subtitle_color')}
        {this.renderBuildFieldColor(offer, 'branding.primary_dark_color', false)}
        {this.renderBuildFieldColor(offer, 'branding.primary_light_color', false)}
        {this.renderBuildFieldColor(offer, 'branding.accent_color', false)}
        {this.renderBuildFieldColor(offer, 'branding.accent_dark_color', false)}
        {this.renderBuildFieldColor(offer, 'branding.text_primary_color', false)}
        {this.renderBuildFieldColor(offer, 'branding.secondary_text_color', false)}
        {this.renderBuildFieldColor(offer, 'branding.icons_color', false)}
        {this.renderBuildFieldColor(offer, 'branding.divider_color', false)}
        {this.renderBuildFieldColor(offer, 'branding.background_light_color', false)}
        <hr />
        {this.renderTable(this._buildColumnsExternals, externals, 'build_externals')}
        {this.renderTable(this._buildColumnsSocials, socials, 'build_socials')}
        {this.renderTable(this._buildColumnsAnalytics, analytics, 'build_analytics')}
      </React.Fragment>
    );
  }

  renderNotification() {
    const { notificationsData, notifications } = this.state;
    return (
      <>
        {notifications.map((notification, index) => {
          const usersList = notificationsData[index][notification.key].map((value: any) => value.user);
          const notificationValue = makeLinkListValue(usersList, 'email', makeUserUrl, 'view');
          return <InfoRowLinkList key={notification.id} label={notification.name} value={notificationValue} />;
        })}
      </>
    );
  }

  renderTab(activeTab: string) {
    const { data } = this.props;
    if (!data) {
      return null;
    }
    let tab = null;
    switch (activeTab) {
      case VIEW_TABS.offer:
        tab = (
          <AccessControl permission="offers:offer:view">
            {this.renderOffer(data)}
          </AccessControl>
        );
        break;
      case VIEW_TABS.branding:
        tab = (
          <AccessControl permission="offers:branding:view">
            {this.renderBranding(data)}
          </AccessControl>
        );
        break;
      case VIEW_TABS.items:
        tab = (
          <AccessControl permission="offers:items:view">
            <TabViewItems />
          </AccessControl>
        );
        break;
      case VIEW_TABS.registration:
        tab = (
          <AccessControl permission="offers:registration:view">
            {this.renderRegistration(data)}
          </AccessControl>
        );
        break;
      case VIEW_TABS.content:
        tab = (
          <AccessControl permission="offers:content:view">
            {this.renderContent(data)}
          </AccessControl>
        );
        break;
      case VIEW_TABS.build:
        tab = (
          <AccessControl permission="offers:build:view">
            {this.renderBuild(data)}
          </AccessControl>
        );
        break;
      case VIEW_TABS.notifications:
        tab = (
          <AccessControl permission="offers:notifications:edit">
            {this.renderNotification()}
          </AccessControl>
        );
        break;
      case VIEW_TABS.others:
        tab = (
          <AccessControl permission="offers:others:view">
            <TabOthers offer={data} hasControl={false} />
          </AccessControl>
        );
        break;
    }
    return (
      <React.Fragment>
        <LinkBlock editLink={makeOfferUrlTo(data, 'edit')} />
        {tab}
      </React.Fragment>
    );
  }

  render() {
    const {
      isLoading,
      data,
      match,
      history,
    } = this.props;
    return (
      <Waiter isLoading={isLoading} data={data}>
        <Tabs
          action="view"
          history={history}
          match={match}
          offerId={get(data, 'id', '')}
          offerName={get(data, 'name', '')}
        >
          {activeTab => this.renderTab(activeTab)}
        </Tabs>
      </Waiter>
    );
  }
}

export default flow([
  graphqlById({
    query: getOfferQuery,
    queryType: 'OfferQuery',
  }),
  withTranslation('offers'),
])(PageView);
