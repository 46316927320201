import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  client,
  gql,
} from 'features/graphql';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  IUser,
  IUserQueryResponse,
} from '../../types';
import ReactSelect from 'features/ui/ReactSelect';

interface IUserSelectProps extends ISharedSelectProps {
  data: IUser[];
  withOfferId?: boolean;
}

interface IUserSelectState {
  options: ISelectOption[];
}

class UserSelect extends React.PureComponent<IUserSelectProps, IUserSelectState> {
  static getDerivedStateFromProps(props: any) {
    const { data, withOfferId } = props;
    return {
      options: UserSelect.mapUsersToOptions(data, withOfferId),
    };
  }

  static mapUsersToOptions = (data: IUser[], withOfferId: boolean = false) => {
    interface IEmails { [key: string]: ID[]; }

    if (withOfferId) {
      return data.map((user: IUser) => ({
        label: `${user.offer.id} | ${user.email}`,
        value: user.id,
      }));
    }
    const emails: IEmails = data.reduce(
      (acc: IEmails, user: IUser) => {
        const { email } = user;
        if (email in acc) {
          acc[email].push(user.id);
        } else {
          acc[email] = [user.id];
        }
        return acc;
      },
      {},
    );
    return Object.keys(emails).map((email: string) => {
      return {
        value: emails[email].join(','),
        label: email,
      };
    });
  }

  state = {
    options: [],
  };

  loadOptions = (value: string) => {
    const { withOfferId } = this.props;
    return client.query<IUserQueryResponse>({
      query: QUERY,
      variables: {
        pageNum: 1,
        perPage: 100,
        filterByFields: {
          email: {
            value,
            operator: 'like',
          },
        },
      },
    }).then((res) => {
      if (res.data && res.data.UserQuery) {
        return UserSelect.mapUsersToOptions(res.data.UserQuery.items, withOfferId);
      }
      return [];
    });
  };

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
      isMulti,
    } = this.props;
    const { options } = this.state;
    return (
      <ReactSelect
        id={id}
        name={name}
        options={options}
        isMulti={!!isMulti}
        value={value}
        async
        loadOptions={this.loadOptions}
        minSearchLength={1}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }
}

const QUERY = gql`
  query getUsersForSelect(
    $id: [ID]
    $pageNum: Int
    $perPage: Int
    $filterByFields: UserFilterByFields
  ) {
    UserQuery(
      id: $id
      page: $pageNum
      perPage: $perPage
      filterByFields: $filterByFields
    ) {
      items {
        id
        email
        offer {
          id
        }
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, IUserQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.UserQuery ? data.UserQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, IUserQueryResponse, IListQueryVariables, any>(
  QUERY,
  {
    props: mapResultsToProps,
    options: ({ value }) => {
      return {
        variables: {
          id: value && value.length ? String(value).split(',') : undefined,
          pageNum: 1,
          perPage: 100,
        },
      };
    },
  },
)(UserSelect);
