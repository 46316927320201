import React from 'react';
import moment from 'moment';
import { RowRenderProps } from 'react-table';
import i18n from 'features/intl/i18n';
import DeleteButton from 'features/ui/DeleteButton';
import SubscriptionsSelect from 'features/subscriptions/lib/SubscriptionsSelect';
import deleteUserSubscriptionQuery from '../queries/deleteUserSubscription.gql';
import { client } from 'features/graphql';
import { Confirm } from 'features/ui/Modal';
import { toast } from 'features/ui/Toast';

const CreateModal = ({ id }: { id: ID }) => {
  const [isModal, setIsModal] = React.useState(false);
  const handleClick = () => {
    setIsModal(true);
  };
  const onDelete = () => {
    if (id === null) {
      return;
    }
    client.mutate({
      mutation: deleteUserSubscriptionQuery,
      variables: {
        id,
      },
    })
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        toast.error(i18n.t('dict:error'));
      });
  };
  return (
    <>
      <DeleteButton onClick={handleClick} />
      <Confirm
        isOpened={isModal}
        title={i18n.t('user_subscriptions:delete_user_subscription')}
        description={i18n.t('user_subscriptions:delete_user_subscription_description', { id })}
        onClose={() => setIsModal(false)}
        onResolve={onDelete}
        type="danger"
      />
    </>
  );
};

export default () => [
  {
    Cell: (row: RowRenderProps) => (row.value.email),
    Header: i18n.t('users:email'),
    accessor: 'user',
  },
  {
    Cell: (row: RowRenderProps) => (row.value.name),
    Header: i18n.t('subscriptions:name_column'),
    accessor: 'subscription',
    Filter: ({ filter, onChange }: any) => (
      <SubscriptionsSelect
        onChange={onChange}
        value={filter && filter.value}
        isMulti={false}
      />
    ),
  },
  {
    Cell: (row: RowRenderProps) => (row.value),
    Header: i18n.t('user_subscriptions:cost'),
    accessor: 'cost',
  },
  {
    Cell: (row: RowRenderProps) => moment(row.value).format('ll'),
    Header: i18n.t('user_subscriptions:from'),
    accessor: 'from',
  },
  {
    Cell: (row: RowRenderProps) => moment(row.value).format('ll'),
    Header: i18n.t('user_subscriptions:to'),
    accessor: 'to',
  },
  {
    Cell: (row: RowRenderProps) => {
      if (row.value) {
        return moment(row.value).format('ll');
      }
      return null;
    },
    Header: i18n.t('user_subscriptions:canceled_at'),
    accessor: 'canceled_at',
  },
  {
    Header: '',
    Cell: ({ value }: RowRenderProps) => {
      const { cost, id } = value;
      if (cost === 0) {
        return <CreateModal id={id} />;
      }
      return null;
    },
    accessor: (row: RowRenderProps) => row,
    id: 'deleteButton',
    className: 'text-right',
    filterable: false,
    maxWidth: 50,
  },
];
