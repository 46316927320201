import { graphql, OptionProps } from 'react-apollo';
import React from 'react';
import { Link } from 'react-router-dom';
import CardPage from 'features/ui/Page/CardPage';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FEATURE_ICON } from 'features/pushes/consts';
import { makeNewPushUrl } from 'features/pushes/lib/url';
import getPushTaskQuery from 'features/pushes/queries/getPushTaskQuery.gql';
import getPushExtraDataById from 'features/pushes/queries/getPushExtraDataById.gql';
import moment from 'moment';
import { ISharedSelectProps } from 'features/ui/ReactSelect/types';
import Spinner from 'features/ui/Spinner';
import {
  IPushes,
  IPushQueryResponse,
} from 'features/pushes/types';
import { IListQueryVariables } from 'features/types';
import { flow } from 'lodash';
import {
  InfoRow,
  InfoRowLinkList,
  InfoRowText,
} from 'features/ui/InfoRow';
import { makeLinkListValue } from 'lib/makeLinkListValue';
import { makeOfferUrl } from 'features/offers/lib/url';
import { makeItemUrlTo } from 'features/items/lib/url';
import { client } from 'features/graphql';
import { makeUserUrl } from 'features/users/lib/url';

import { makeUserGroupUrl } from 'features/usergroups/lib/url';

interface IPushViewProps extends WithTranslation {
  push: IPushes;
}

interface IPushViewState {
  isLoading: boolean;
  users: Array<{
    id: string;
    text: string;
    to: string;
  }>;
  groups: Array<{
    id: string;
    text: string;
    to: string;
  }>;
}

class PageCreate extends React.Component<IPushViewProps, IPushViewState> {
  constructor(props: IPushViewProps) {
    super(props);
    this.state = {
      users: [],
      groups: [],
      isLoading: true,
    };
  }

  componentDidUpdate(prevProps: Readonly<IPushViewProps>) {
    const { push } = this.props;

    if (push !== prevProps.push && push) {
      client.query({
        query: getPushExtraDataById,
        variables: {
          page: 1,
          perPage: 10000,
          userIds: push.user_ids || [],
          groupsIds: push.user_groups || [],
          groupsIdsLength: !!push.user_groups.length,
          userIdsLength: !!push.user_ids.length,
        },
      }).then((extraData) => {
        this.setState({
          users: extraData.data && extraData.data.users && extraData.data.users.items ? makeLinkListValue(extraData.data.users.items, 'email', makeUserUrl, 'view') : [],
          groups: extraData.data && extraData.data.groups && extraData.data.groups.items ? makeLinkListValue(extraData.data.groups.items, 'name', makeUserGroupUrl, 'view') : [],
          isLoading: false,
        });
      }).catch((err) => {
        global.console.log(err);
        this.setState({ isLoading: false });
      });
    }
  }

  renderLinkedContent = () => {
    const { t, push } = this.props;
    const item = {
      type: push.content_type,
      id: push.content_id,
    };
    if (push.content_type === 'url') {
      return (
        <a href={push.content_url} rel="external noopener noreferrer" target="_blank">
          {push.content_url}
        </a>
      );
    }
    return (
      <Link to={makeItemUrlTo(item, 'view')}>
        {t(`pushes:types:${push.content_type}`)}
      </Link>
    );
  }

  renderExtraParams() {
    const { users, groups, isLoading } = this.state;
    const { t } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    if (!users.length && !groups.length) {
      return (
        <InfoRowText label={t('users')} value={t('to_all')} />
      );
    }

    return (
      <>
        <InfoRowLinkList label={t('users')} value={users} byLine={true} max={10} />
        <InfoRowLinkList label={t('user_groups')} value={groups} byLine={true} max={10} />
      </>
    );
  }

  render() {
    const { t, push } = this.props;

    if (!push) {
      return null;
    }
    const offersLinkListValue = makeLinkListValue([push.offer], 'name', makeOfferUrl, 'view');
    return (
      <CardPage
        icon={FEATURE_ICON}
        title="Отправленное push-уведомление"
        crumbs={[{ link: makeNewPushUrl(), name: 'Push-уведомления' }]}
      >
        <InfoRowText label="ID" value={push.id} />
        <InfoRowText label={t('title')} value={push.title} />
        <InfoRowText label={t('message')} value={push.message} />
        <InfoRowText label={t('status')} value={t(push.status)} />
        <InfoRowLinkList
          label={t('offers:offers')}
          value={offersLinkListValue}
          byLine={true}
          max={10}
        />
        <InfoRow label={t('resource_type')}>
          {this.renderLinkedContent()}
        </InfoRow>
        <InfoRowText label={t('created_at')} value={moment(push.created_at).format('lll')} />
        {
          push.send_at ?
            <InfoRowText label={t('send_at')} value={moment(push.send_at).format('lll')} />
            : null
        }
        {this.renderExtraParams()}
      </CardPage>
    );
  }
}

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, IPushQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    push: data && data.PushTaskQuery ? data.PushTaskQuery.items[0] : undefined,
    ...ownProps,
  };
};

export default flow(
  graphql<ISharedSelectProps & WithTranslation, IPushQueryResponse, IListQueryVariables, any>(getPushTaskQuery, {
    props: mapResultsToProps,
    options: props => ({
      variables: {
        id: props.params.id,
      },
      fetchPolicy: 'network-only',
    }),
  }),
  withTranslation('pushes'),
)(PageCreate);
