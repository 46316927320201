import React from 'react';
import { get, isNumber } from 'lodash';
import {
  Formik,
  FormikActions,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import filterChangedValues from 'lib/filterChangedValues';
import field from 'lib/field';
import { toStr } from 'lib/crudConvert';
import { crudMutate } from 'features/common/helpers';
import BaseForm from './Form';

import getPromocodeQuery from 'features/promocodes/queries/getPromocodeQuery.gql';
import updatePromocodeMutation from 'features/promocodes/queries/updatePromocodeMutation.gql';
import createPromocodeMutation from 'features/promocodes/queries/createPromocodeMutation.gql';

import { IFormValues } from 'features/promocodes/types';
import { IPromocode } from 'features/types';

interface IFormProps extends WithTranslation {
  data?: IPromocode;
}

class Form extends React.PureComponent<IFormProps> {
  validationSchema() {
    const { t } = this.props;
    return Yup.object().shape({
      code: Yup.string()
        .required(t('forms:required_field', { field: t('code') }))
        .max(50, t('forms:wrong_max_string_size', { field: t('code'), amount: 50 })),
      count: Yup.number()
        .required(t('forms:required_field', { field: t('count') }))
        .min(1, t('forms:wrong_min_value', { field: t('count'), value: 1 })),
      offer: Yup.number().min(1, t('forms:required_field', { field: t('offer') })),
      items: Yup.array(),
      subscriptions: Yup.array(),
    });
  }

  getChangedValues(values: IFormValues) {
    const formData = this.buildFormValues();
    return filterChangedValues(formData, values);
  }

  onSubmit = (values: IFormValues, formActions: FormikActions<IFormValues>) => {
    const id = get(this.props, 'data.id', undefined);
    const promoCode = id ? this.getChangedValues({ ...values }) : { ...values };

    if ('expire_date' in promoCode) {
      promoCode.expire_date = toStr(promoCode.expire_date);
    }

    if ('subscriptions' in promoCode) {
      promoCode.subscriptions = values.subscriptions;
      promoCode.trial_days = isNumber(values.trial_days) ? values.trial_days : null;
      promoCode.expire_days = isNumber(values.expire_days) ? values.expire_days : null;
    }
    if (promoCode.promocode_type === 'promocode_type_subscriptions' && promoCode.items) {
      promoCode.items = [];
    }
    if (promoCode.promocode_type === 'promocode_type_item') {
      promoCode.subscriptions = [];
      promoCode.discount_percent = null;
      promoCode.discount_price = null;
    }
    if (promoCode.discount_type && promoCode.discount_type === 'discount_type_percent') {

      promoCode.discount_price = isNumber(values.discount_price) ? values.discount_price : null;
      promoCode.discount_percent = null;
    }
    if (promoCode.discount_type && promoCode.discount_type === 'discount_type_price') {
      promoCode.discount_percent = isNumber(values.discount_percent) ? values.discount_percent : null;
      promoCode.discount_price = null;
    }

    delete promoCode.promocode_type;
    delete promoCode.discount_type;

    crudMutate({
      id,
      formActions,
      mutation: id ? updatePromocodeMutation : createPromocodeMutation,
      variables: id ? { id, promoCode } : { promoCode },
      redirect: '/promocodes',
      updateRefetchQuery: getPromocodeQuery,
      check: !!Object.keys(promoCode).length,
    });
  };

  buildFormValues(): IFormValues {
    const { data } = this.props;
    return {
      code: field(data, 'code', ''),
      count: field(data, 'count', 1),
      offer: field(data, 'offer.id', 0),
      is_active: field(data, 'is_active', true),
      expire_date: field(data, 'expire_date', ''),
      items: data && data.items ? data.items.map(i => +i.id) : [],
      subscriptions: data && data.subscriptions ? data.subscriptions.map(s => s.id): [],
      trial_days: data && data.subscriptions.length ? data.subscriptions[0].trial_days : null,
      expire_days: data && data.subscriptions.length ? data.subscriptions[0].expire_days : null,
      promocode_type: data && data.subscriptions.length ? 'promocode_type_subscriptions' : 'promocode_type_item',
      discount_type: data && data.discount_percent ? 'discount_type_percent' : 'discount_type_price',
      discount_price: field(data, 'discount_price', null),
      discount_percent: field(data, 'discount_percent', null),
    };
  }

  renderForm = (props: FormikProps<IFormValues>) => {
    return (
      <BaseForm {...props} item={this.props.data} />
    );
  }

  render() {
    return (
      <Formik
        validationSchema={this.validationSchema()}
        enableReinitialize
        initialValues={this.buildFormValues()}
        onSubmit={this.onSubmit}
        render={this.renderForm}
      />
    );
  }
}

export default withTranslation('promocodes')(Form);
