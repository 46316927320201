import { FEATURE_ICON as BOOKS_FEATURE_ICON } from 'features/books/consts';
import { FEATURE_ICON as AUDIOBOOKS_FEATURE_ICON } from 'features/audiobooks/consts';
import { FEATURE_ICON as HYBRIDS_FEATURE_ICON } from 'features/hybrids/consts';
import { FEATURE_ICON as DOCUMENTS_FEATURE_ICON } from 'features/documents/consts';
import { FEATURE_ICON as VIDEOS_FEATURE_ICON } from 'features/videos/consts';
import { FEATURE_ICON as COLLECTIONS_FEATURE_ICON } from 'features/collections/consts';
import { FEATURE_ICON as COURSES_FEATURE_ICON } from 'features/courses/consts';
import { FEATURE_ICON as USERS_SUBS_ICON } from 'features/userSubscriptions/consts';

import { FEATURE_ICON as CATEGORIES_FEATURE_ICON } from 'features/categories/consts';
import { FEATURE_ICON as BADGES_FEATURE_ICON } from 'features/badges/consts';
import { FEATURE_ICON as BANNERS_FEATURE_ICON } from 'features/banners/consts';
import { FEATURE_ICON as PROMOCODES_FEATURE_ICON } from 'features/promocodes/consts';
import { FEATURE_ICON as USERS_FEATURE_ICON } from 'features/users/consts';
import { FEATURE_ICON as USER_GROUPS_FEATURE_ICON } from 'features/usergroups/consts';
import { FEATURE_ICON as CREATORS_FEATURE_ICON } from 'features/creators/consts';
import { FEATURE_ICON as OFFERS_FEATURE_ICON } from 'features/offers/consts';
import { FEATURE_ICON as DEPLOY_FEATURE_ICON } from 'features/deploy/consts';
import { FEATURE_ICON as ORDERS_FEATURE_ICON } from 'features/orders/consts';
import { FEATURE_ICON as AUTOIMPORT_FEATURE_ICON } from 'features/autoimport/consts';
import { FEATURE_ICON as PUSH_FEATURE_ICON } from 'features/pushes/consts';
import { FEATURE_ICON as FEATURE_TOGGLE_ICON } from 'features/featureToggle/consts';
import { FEATURE_ICON as EVENTS_FEATURE_ICON } from 'features/events/consts';
import { FEATURE_ICON as POPULAR_REQUEST_ICON } from 'features/popularRequests/consts';
import { LAYOUT_ICON } from 'features/layouts/consts';
import { BLOCK_ICON } from 'features/blocks/consts';

export default {
  items: [
    {
      name: 'Dashboard',
      url: '/',
      icon: 'icon-speedometer',
      permission: 'dashboard:access',
    },
    {
      name: 'Категории',
      url: '/categories',
      icon: CATEGORIES_FEATURE_ICON,
      permission: 'categories:access',
    },
    {
      name: 'Промокоды',
      url: '/promocodes',
      icon: PROMOCODES_FEATURE_ICON,
      permission: 'promocodes:access',
    },
    {
      name: 'Бейджи',
      url: '/badges',
      icon: BADGES_FEATURE_ICON,
      permission: 'badges:access',
    },
    {
      name: 'Баннеры',
      url: '/banners',
      icon: BANNERS_FEATURE_ICON,
      permission: 'banners:access',
    },
    {
      name: 'Популярные запросы',
      url: '/popular_requests',
      icon: POPULAR_REQUEST_ICON,
      permission: 'offers:access',
    },
    {
      name: 'Предложения',
      url: '/offers',
      icon: OFFERS_FEATURE_ICON,
      permission: 'offers:access',
    },
    {
      name: 'Настройка главной',
      icon: 'icon-list',
      url: '/items',
      permission: 'offers:access',
      children: [
        {
          name: 'Лейауты',
          url: '/layouts',
          icon: LAYOUT_ICON,
          permission: 'offers:access',
        },
        {
          name: 'Блоки',
          url: '/layout-blocks',
          icon: BLOCK_ICON,
          permission: 'offers:access',
        },
      ],
    },
    {
      name: 'Подписки B2C',
      icon: COLLECTIONS_FEATURE_ICON,
      permission: 'offers:access',
      children: [
        {
          name: 'Все подписки',
          url: '/subscriptions',
          icon: COLLECTIONS_FEATURE_ICON,
          permission: 'offers:access',
        },
        {
          name: 'Пользователи и подписки',
          url: '/user-subscriptions',
          icon: USERS_SUBS_ICON,
          permission: 'offers:access',
        },
      ],
    },
    {
      name: 'Развертывание',
      url: '/deploy',
      icon: DEPLOY_FEATURE_ICON,
      permission: 'deploy:access',
    },
    {
      name: 'Заказы',
      url: '/orders',
      icon: ORDERS_FEATURE_ICON,
      permission: 'orders:access',
    },
    {
      name: 'Creators',
      url: '/creators',
      icon: CREATORS_FEATURE_ICON,
      permission: 'creators:access',
    },
    {
      name: 'Пользователи',
      url: '/users',
      icon: USERS_FEATURE_ICON,
      permission: 'users:access',
    },
    {
      name: 'Группы',
      url: '/usergroups',
      icon: USER_GROUPS_FEATURE_ICON,
      permission: 'usergroups:access',
    },
    {
      name: 'Управление пользователями',
      url: '/usersmanagement',
      icon: USER_GROUPS_FEATURE_ICON,
      permission: 'users:access',
    },
    {
      name: 'Автозаливатор',
      url: '/autoimports',
      icon: AUTOIMPORT_FEATURE_ICON,
      permission: 'autoimports:access',
    },
    {
      name: 'Push-уведомления',
      url: '/pushes',
      icon: PUSH_FEATURE_ICON,
      permission: 'items:access', // ACCESS?
    },
    {
      name: 'Итемы',
      icon: 'icon-list',
      url: '/items',
      permission: 'items:access',
      children: [
        {
          name: 'Все',
          url: '/items',
          icon: 'icon-list',
          permission: 'items:access',
        },
        {
          name: 'Книги',
          url: '/books',
          icon: BOOKS_FEATURE_ICON,
          permission: 'books:access',
        },
        {
          name: 'Гибриды',
          url: '/hybrids',
          icon: HYBRIDS_FEATURE_ICON,
          permission: 'hybrids:access',
        },
        {
          name: 'Документы',
          url: '/documents',
          icon: DOCUMENTS_FEATURE_ICON,
          permission: 'documents:access',
        },
        {
          name: 'Аудиокниги',
          url: '/audiobooks',
          icon: AUDIOBOOKS_FEATURE_ICON,
          permission: 'audiobooks:access',
        },
        {
          name: 'Видео',
          url: '/videos',
          icon: VIDEOS_FEATURE_ICON,
          permission: 'videos:access',
        },
        {
          name: 'Коллекции',
          url: '/collections',
          icon: COLLECTIONS_FEATURE_ICON,
          permission: 'collections:access',
        },
        {
          name: 'Статьи',
          url: '/articles',
          icon: DOCUMENTS_FEATURE_ICON,
          permission: 'books:access',
        },
        {
          name: 'Курсы',
          url: '/courses',
          icon: COURSES_FEATURE_ICON,
          permission: 'books:access',
        },
        {
          name: 'Тесты',
          url: '/tests',
          icon: COURSES_FEATURE_ICON,
          permission: 'books:access',
        },
        {
          name: 'Мероприятия',
          url: '/events',
          icon: EVENTS_FEATURE_ICON,
          permission: 'collections:access',
        },
      ],
    },
    {
      name: 'Feature Toggle',
      url: '/feature-toggle',
      icon: FEATURE_TOGGLE_ICON,
      permission: 'offers:access',
    },
  ],
};
