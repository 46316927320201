import {
  IImage,
  IItem,
  IOffer,
  IUserGroup,
} from 'features/types';
import { ISubscription } from 'features/subscriptions/types';

export const enum UserRoleEnum {
  administrator = 'administrator',
  hr = 'hr',
  user = 'user',
  promo = 'promo',
  sales = 'sales',
  outsource = 'outsource',
  support = 'support',
}

export const enum UserGenderEnum {
  male = 'male',
  female = 'female',
}

export interface IUserQueryData {
  items: IUser[];
}

export interface IUserQueryResponse {
  UserQuery: IUserQueryData;
}

export interface IUser {
  id: ID;
  email: string;
  role: UserRoleEnum;
  is_activated: boolean;
  activation_code: string;
  expire_date: string;
  first_name: string;
  last_name: string;
  city: string;
  position: string;
  department: string;
  gender: UserGenderEnum;
  birthday: string;
  avatar: IImage;
  sso: string;
  device_id: string;
  external_id: string;
  used_pin: number;
  is_subscribed: boolean;
  is_auto_registered: boolean;
  is_deleted: boolean;
  can_be_restored: boolean;
  created_at: string;
  updated_at: string;
  groups: IUserGroup[];
  offer: IOffer;
  scopes: number[];
  additionalOffers: IOffer[];
  itemsInventory: IItem[];
  itemsFavorite: IItem[];
  itemsDownloads: IItem[];
  subscriptions: ISubscription[];
}

export interface IUserInput {
  email?: string | null;
  role?: UserRoleEnum | null;
  expire_date?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  city?: string | null;
  position?: string | null;
  department?: string | null;
  gender?: UserGenderEnum | null;
  birthday?: string | null;
  is_activated?: boolean;
  is_subscribed?: boolean;
  is_auto_registered?: boolean;
  is_deleted?: boolean;
  can_be_restored?: boolean;
  groups?: ID[];
  scopes?: number[];
  offer?: ID;
  labEnabled?: boolean;
}

export interface IFormValues {
  id: number;
  email: string;
  role: UserRoleEnum;
  is_activated: boolean;
  first_name: string;
  last_name: string;
  city: string;
  position: string;
  department: string;
  is_deleted: boolean;
  is_subscribed: boolean;
  can_be_restored: boolean;
  offer: number;
  birthday: string;
  expire_date: string;
  created_at: string;
  groups: ID[];
  gender: UserGenderEnum;
  password: string;
  labEnabled: boolean;
  [key: string]:
    undefined |
    number |
    string |
    boolean |
    ID[] |
    number[] |
    UserRoleEnum |
    UserGenderEnum;
}
