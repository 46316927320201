import React from 'react';
import { Input } from 'reactstrap';
import { stubTrue } from 'lodash';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'Formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import LocalStorage from 'features/store/LocalStorage';

import { IFormValues, IPushes } from 'features/pushes/types';
import OffersSelectWithButtons from 'features/offers/lib/OffersSelectWithButtons';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import CategoriesSelect from 'features/categories/lib/CategoriesSelect';
import { client } from 'features/graphql';
import getItemTypeByIdQuery from 'features/pushes/queries/getItemTypeByIdQuery.gql';
import { get } from 'lodash';
import { toast } from 'features/ui/Toast';
import { PUSH_OFFERS } from 'features/pushes/consts';
import UserGroupsSelect from 'features/usergroups/lib/UserGroupsSelect';
import UserSelect from 'features/users/lib/UserSelect';
import DatePicker from 'features/ui/DatePicker';
import TimePicker from 'features/ui/TimePicker';
import { Moment } from 'features/types';
import moment from 'moment';

interface IBaseFormProps extends FormikProps<IFormValues>, WithTranslation {
  item: IPushes;
}

class BaseForm extends React.PureComponent<IBaseFormProps> {
  onChangeOffers = (ids: ID[]) => {
    const { setFieldValue } = this.props;
    LocalStorage.set(PUSH_OFFERS, ids.slice());
    setFieldValue('offers', ids.slice());
  };

  onBlurResourceLink = () => {
    const { setFieldTouched } = this.props;
    setFieldTouched('resource_link', true);
  }

  onChangeResourceLink = (value: string) => {
    const { setFieldValue } = this.props;
    setFieldValue('resource_link', value);
  }

  onChangeUserGroups = (value: number) => {
    const { setFieldValue } = this.props;
    setFieldValue('user_groups', value);
  }

  onChangeUsers = (value: number) => {
    const { setFieldValue } = this.props;
    setFieldValue('user_ids', value);
  }

  onChangeDate = (date: Moment | null) => {
    const { setFieldValue } = this.props;
    setFieldValue('send_at', date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '');
  };

  onChangeItemLink = (id: string) => {
    const { setFieldValue } = this.props;
    client.query({
      variables: {
        id,
      },
      query: getItemTypeByIdQuery,
    })
      .then((data: any) => {
        const type = get(data, 'data.ItemQuery.items[0].type', null);
        setFieldValue('item_type', type);
        setFieldValue('resource_link', id);
      })
      .catch(() => toast.error('Произошла сетевая ошибка'));
  }

  renderResourceLinkInputItem() {
    const {
      t,
      values,
    } = this.props;
    return (
      <FormRow label={t('resource_link')} id="resource_link" required>
        <ItemsSelect
          id="resource_link"
          isMulti={false}
          name="resource_link"
          onBlur={this.onBlurResourceLink}
          onChange={this.onChangeItemLink}
          value={parseInt(values.resource_link || '0', 10)}
        />
      </FormRow>
    );
  }

  renderResourceLinkInputCategory() {
    const {
      t,
      values,
    } = this.props;
    return (
      <FormRow label={t('resource_link')} id="resource_link" required>
        <CategoriesSelect
          id="resource_link"
          name="resource_link"
          value={parseInt(values.resource_link || '0', 10)}
          onChange={this.onChangeResourceLink}
          onBlur={this.onBlurResourceLink}
        />
      </FormRow>
    );
  }

  renderResourceLinkInputUrl() {
    const {
      t,
      values,
      handleBlur,
      handleChange,
    } = this.props;
    return (
      <FormRow label={t('resource_link')} id="resource_link" required>
        <Input
          type="text"
          id="resource_link"
          name="resource_link"
          placeholder="https://..."
          value={values.resource_link}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    );
  }

  render() {
    const {
      t,
      item,
      values,
      handleBlur,
      handleChange,
    } = this.props;
    return (
      <FormFrame
        id={item && item.id}
        cancelLink="/pushes"
        submitBtn="Отправить"
        {...this.props}
      >
        <FormRow label={t('title')} id="title" required>
          <Input
            type="text"
            id="title"
            name="title"
            placeholder="Текст"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>
        <FormRow label={t('message')} id="message" required>
          <Input
            type="text"
            id="message"
            name="message"
            placeholder="Сообщение"
            value={values.message}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>
        <FormRow label={t('resource_type')} id="resource_type">
          <Input
            id="resource_type"
            name="resource_type"
            type="select"
            value={values.resource_type}
            onChange={handleChange}
          >
            <option value="category">{t('resource_type_category')}</option>
            <option value="item">{t('resource_type_item')}</option>
            <option value="url">{t('resource_type_url')}</option>
          </Input>
        </FormRow>

        {values.resource_type === 'category' ? this.renderResourceLinkInputCategory() : null}
        {values.resource_type === 'item' ? this.renderResourceLinkInputItem() : null}
        {values.resource_type === 'url' ? this.renderResourceLinkInputUrl() : null}

        <FormRow key="offers" label={t('offers:offers')} id="offers" text={t('offers:offers')}>
          <OffersSelectWithButtons
            id="offers"
            name="offers"
            value={values.offers}
            onChange={this.onChangeOffers}
          />
        </FormRow>
        <FormRow
          id="user_groups"
          shouldUpdate={stubTrue}
          label={t('user_groups')}
        >
          <UserGroupsSelect
            id="user_groups"
            name="user_groups"
            isMulti={true}
            offer={values.offers ? values.offers.join(',') : ''}
            value={values.user_groups}
            onChange={this.onChangeUserGroups}
          />
        </FormRow>
        <FormRow
          id="user_ids"
          label={t('users:users')}
        >
          <UserSelect
            id="user_ids"
            name="user_ids"
            withOfferId
            onChange={this.onChangeUsers}
            isMulti
            value={values.user_ids}
          />
        </FormRow>
        <FormRow
          id="send_at"
          label={t('send_at')}
          text={t('send_at_description')}
        >
          <DatePicker
            id="send_at"
            value={values.send_at ? Date.parse(values.send_at) : null}
            onChange={(date: Moment | null) => this.onChangeDate(date)}
          />
          {values.send_at
            ? (
              <TimePicker
                onChange={(date: Moment | null) => this.onChangeDate(date)}
                value={Date.parse(values.send_at)}
              />) : null
          }
        </FormRow>
      </FormFrame>
    );
  }
}

export default withTranslation('pushes')(BaseForm);
