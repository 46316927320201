import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { get } from 'lodash';
import moment from 'moment';
import {
  makeLinkListValue,
  makeLinkListValueToItem,
} from 'lib/makeLinkListValue';
import {
  InfoRowDateBlock,
  InfoRowImage,
  InfoRowLink,
  InfoRowLinkList,
  InfoRowSwitch,
  InfoRowText,
} from 'features/ui/InfoRow';
import LinkBlock from 'features/ui/LinkBlock';
import { makeItemUrlTo } from 'features/items/lib/url';
import { makeUserGroupUrl } from 'features/usergroups/lib/url';
import { makeOfferUrl } from 'features/offers/lib/url';
import {
  makeUserUrl,
  makeUserUrlTo,
} from 'features/users/lib/url';
import { createView } from 'features/common';
import getEntity from '../queries/getUserForPageViewQuery.gql';

import { FEATURE_ICON } from '../consts';

import { IItem } from 'features/items/types';
import { IUser } from '../types';
import { makeSubscriptionUrl } from "features/subscriptions/lib/url";

interface ILinkWithAmount {
  id: ID;
  to: string;
  text: string;
  amount: number;
}

const View = createView<IUser>(getEntity, 'UserQuery');

class PageView extends React.PureComponent<WithTranslation> {
  itemsDownloadsLinkListValue(user: IUser) {
    return (Object.values(user.itemsDownloads.reduce(
      (acc: any, item: IItem) => {
        if (!(item.id in acc)) {
          acc[item.id] = {
            id: item.id,
            amount: 1,
            text: `[${item.type}] ${item.name}`,
            to: makeItemUrlTo(item, 'view'),
          };
        } else {
          acc[item.id].amount += 1;
        }
        return acc;
      },
      {},
    )) as ILinkWithAmount[]).map(link => ({
      id: link.id,
      to: link.to,
      text: `${link.text} (${link.amount})`,
    }));
  }

  renderChildren = (user: IUser) => {
    const { t } = this.props;
    const role = user.role && t(user.role);
    const gender = user.gender && t(user.gender);
    const offerId = get(user, 'offer.id', null);
    const expireDate = user.expire_date && moment(user.expire_date).format('ll');
    const birthday = user.expire_date && moment(user.birthday).format('ll');
    const groupsLinkListValue = makeLinkListValue(user.groups, 'name', makeUserGroupUrl, 'view');
    const additionalOffersLinkListValue = makeLinkListValue(user.additionalOffers || [], 'name', makeOfferUrl, 'view');
    const itemsInventoryLinkListValue = makeLinkListValueToItem(user.itemsInventory || [], 'name', makeItemUrlTo, 'view');
    const itemsFavoriteLinkListValue = makeLinkListValueToItem(user.itemsFavorite || [], 'name', makeItemUrlTo, 'view');
    const itemsDownloadsLinkListValue = this.itemsDownloadsLinkListValue(user);
    const subscriptionListValue = makeLinkListValue(user.subscriptions || [], 'name', makeSubscriptionUrl, 'view');
    return (
      <React.Fragment>
        <LinkBlock
          editLink={makeUserUrlTo(user, 'edit')}
          inventoryLink={makeUserUrlTo(user, 'view', 'inventory')}
        />
        <InfoRowText label="ID" value={user.id} />
        <InfoRowText label={t('role')} value={role} />
        <InfoRowText label={t('email')} value={user.email} />
        <InfoRowText label={t('expire_date')} value={expireDate} />
        <InfoRowText label={t('used_pin')} value={user.used_pin} />
        <InfoRowImage label={t('avatar')} value={get(user, 'avatar.path', null)} />
        <InfoRowSwitch label={t('is_activated')} value={user.is_activated} on={'dict:yes'} off={'dict:no'} />
        <InfoRowSwitch label={t('is_deleted')} value={user.is_deleted} on={'dict:yes'} off={'dict:no'} />
        <InfoRowSwitch label={t('labEnabled')} value={!!user.scopes.length} on={'dict:yes'} off={'dict:no'} />
        <InfoRowSwitch label={t('is_subscribed')} value={user.is_subscribed} on={'dict:yes'} off={'dict:no'} />
        <InfoRowSwitch label={t('can_be_restored')} value={user.can_be_restored} on={'dict:yes'} off={'dict:no'} />
        <InfoRowText label={t('first_name')} value={user.first_name} />
        <InfoRowText label={t('last_name')} value={user.last_name} />
        <InfoRowText label={t('gender')} value={gender} />
        <InfoRowText label={t('birthday')} value={birthday} />
        <InfoRowText label={t('city')} value={user.city} />
        <InfoRowText label={t('position')} value={user.position} />
        <InfoRowText label={t('department')} value={user.department} />
        <InfoRowText label={t('sso')} value={user.sso} />
        <InfoRowText label={t('device_id')} value={user.device_id} />
        <InfoRowText label={t('external_id')} value={user.external_id} />
        <InfoRowLink
          label={t('offers:offer')}
          id={offerId}
          value={offerId && makeOfferUrl({ id: offerId, action: 'view' })}
          text={get(user, 'offer.name', '')}
        />
        <InfoRowLinkList
          label={t('groups')}
          value={groupsLinkListValue}
          byLine={true}
        />
        <InfoRowLinkList
          label={t('additionalOffers')}
          value={additionalOffersLinkListValue}
          byLine={true}
        />
        <InfoRowLinkList
          label={t('itemsInventory')}
          value={itemsInventoryLinkListValue}
          byLine={true}
          max={5}
        />
        <InfoRowLinkList
          label={t('itemsFavorite')}
          value={itemsFavoriteLinkListValue}
          byLine={true}
          max={5}
        />
        <InfoRowLinkList
          label={t('itemsDownloads')}
          value={itemsDownloadsLinkListValue}
          byLine={true}
          max={5}
        />
        <InfoRowLinkList
          label={t('subscriptions')}
          value={subscriptionListValue}
          byLine={true}
          max={5}
        />
        <hr />
        <InfoRowDateBlock value={user} />
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    return (
      <View
        titleField={'email'}
        icon={FEATURE_ICON}
        crumbs={[{ link: makeUserUrl(), name: t('users') }]}
        children={this.renderChildren}
      />
    );
  }
}

export default withTranslation('users')(PageView);
