import React from 'react';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import { FormikProps } from 'formik';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import { blockParams } from 'features/blocks/consts';
import ColorPicker from 'features/ui/ColorPicker';
import { client, gql } from 'features/graphql';
import ImageUploader from 'features/ui/ImageUploader';
import { makeLayoutBlockUrl } from '../url';
import BlockTypeSelector from '../BlockTypeSelector';
import ReferenceSelect from '../ReferenceSelect';
import {
  IBlock,
  ILayoutFormValues,
} from '../../types';

interface ILayoutFormProps extends FormikProps<ILayoutFormValues>, WithTranslation {
  block?: IBlock;
}

interface ILayoutFormState {
  currentLayoutType: string;
}

class BaseLayoutForm extends React.Component<ILayoutFormProps, ILayoutFormState> {
  constructor(props: ILayoutFormProps) {
    super(props);
    this.state = {
      currentLayoutType: '',
    };
  }

  componentDidMount() {
    const QUERY = gql`
      query getBlockTypes($page: Int, $perPage: Int) {
        LayoutBlockTypeQuery(page: $page, perPage: $perPage) {
          items {
            id
            name
            slug
          }
        }
      }
    `;
    client.query({
      query: QUERY,
      variables: {
        page: 1,
        perPage: 50,
      },
    }).then((res) => {
      const { values: { type } } = this.props;
      const variants = res.data.LayoutBlockTypeQuery.items;
      if (variants.length && type) {
        this.setState({ currentLayoutType: variants.find((variant: IBlock) => variant.id === type).slug });
      }
    }).catch(err => global.console.error(`[Error]: ${err}`));
  }

  onBlur = (name: string) => {
    const { setFieldTouched } = this.props;
    setFieldTouched(name, true);
  };

  onChangeImage = (e: any) => {
    const { handleChange, setFieldValue } = this.props;
    if (e.target.validity.valid) {
      handleChange(e);
      setFieldValue('view.image', e.target.files[0]);
    }
  };

  onTypeChange = (id: number, ds: any, fields: any) => {
    const { setFieldValue } = this.props;
    setFieldValue('type', id);
    this.setState({ currentLayoutType: fields.slug });
  };

  onChangeColor = (color: string) => {
    const { setFieldValue } = this.props;
    setFieldValue('view.bg_color', color);
  };

  onItemsChange = (values: number[]) => this.props.setFieldValue('items', [...values]);

  onReferencesChange = (value: number) => {
    const { values, setFieldValue } = this.props;
    setFieldValue('references', [...values.references, value]);
  }

  onReferencesDelete = (value: number) => {
    const { values, setFieldValue } = this.props;
    setFieldValue('references', values.references.filter(v => v !== value));
  }

  onItemChange = (value: number) => this.props.setFieldValue('items', value);

  renderAdditionalFields = (param: string) => {
    const { currentLayoutType } = this.state;
    if (!currentLayoutType) {
      return null;
    }

    return blockParams[currentLayoutType].params.includes(param) || false;
  }

  render() {
    const {
      block,
      handleBlur,
      handleChange,
      t,
      values,
    } = this.props;

    return (
      <FormFrame
        id={block ? block.id : undefined}
        cancelLink={makeLayoutBlockUrl()}
        {...this.props}
      >
        <FormRow label={t('name')} id="name" required>
          <Input
            type="text"
            id="name"
            name="name"
            placeholder={t('name')}
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow label={t('type')} id="type" required>
          <BlockTypeSelector
            id="type"
            name="type"
            value={values.type}
            onChange={this.onTypeChange}
          />
        </FormRow>
        {this.renderAdditionalFields('references') ? (
          <FormRow id="references" label={t('references')}>
            <ReferenceSelect
              id="references"
              name="references"
              value={values.references}
              onChange={this.onReferencesChange}
              onDelete={this.onReferencesDelete}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('items') ? (
          <FormRow id="items" label={t('items')}>
            <ItemsSelect
              id="items"
              name="items"
              isMulti={true}
              value={values.items}
              isClearable
              onChange={this.onItemsChange}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('image') ? (
          <FormRow
            label={t('image')}
            id="view.image"
            text={t('forms:expected_size', { width: 352, height: 352 })}
            required
            shouldUpdate={() => true}
          >
            <ImageUploader
              id="view.image"
              name="view.image"
              value={values.view && values.view.image && values.view.image.path ? values.view.image.path : undefined}
              onChange={this.onChangeImage}
              onBlur={handleBlur}
              imageWidth={352}
              imageHeight={352}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('item') ? (
          <FormRow id="items" label={t('items')}>
            <ItemsSelect
              id="items"
              name="items"
              isMulti={false}
              value={values.items}
              isClearable
              onChange={this.onItemChange}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('feed_name') ? (
          <FormRow label={t('feed_name')} id="view.feed_name" required>
            <Input
              type="text"
              id="view.feed_name"
              name="view.feed_name"
              placeholder={t('feed_name')}
              value={values.view.feed_name}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </FormRow>
        ) : null}
        {this.renderAdditionalFields('bg_color') ? (
          <FormRow label={t('bg_color')} id="view.bg_color" text={t('forms:example', { text: '#3a4b5c' })}>
            <ColorPicker
              id="view.bg_color"
              name="view.bg_color"
              placeholder={t('bg_color')}
              color={values.view.bg_color}
              onChange={this.onChangeColor}
            />
          </FormRow>
        ) : null}
      </FormFrame>
    );
  }
}

export default withTranslation('blocks')(BaseLayoutForm);
