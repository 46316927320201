export const VIEW_TABS: {[key: string]: string} = {
  branding: 'branding',
  build: 'build',
  content: 'content',
  items: 'items',
  offer: 'offer',
  registration: 'registration',
  notifications: 'notifications',
  others: 'others',
};

export const VIEW_TAB_DEFAULT = VIEW_TABS.offer;

export const RETAIL_ANDROID_BUNDLE_ID = 'ru.alpina.alpina_retail';
export const RETAIL_IOS_BUNDLE_ID = 'ru.alpinabook.reader.iphone';
export const RETAIL_WEB_BUNDLE_ID = 'ru.alpinabook.reader.web';

export const FEATURE_ICON = 'icon-layers';
