export enum OfferSettingsBuildTypeEnum {
  iOS = 'iOS',
  Android = 'Android',
}

export interface IOfferSettingsBuildMain {
  id: ID;
  prefix: string;
}

export interface IOfferSettingsBuildFeatures {
  corporate: boolean;
  in_app_purchase: boolean;
  books: boolean;
  content_table: boolean;
  documents: boolean;
  banners: boolean;
  badges: boolean;
  wishlist: boolean;
  comments: boolean;
  push_notification: boolean;
  local_notification: boolean;
  required_login: boolean;
  socials: boolean;
  pins: boolean;
  user_groups: boolean;
  analytic: boolean;
  app_rate: boolean;
}

export interface IOfferSettingsBuildInterface {
  title_showcase: string;
  title_my_collection: string;
  title_documents: string;
  title_my_documents: string;
}

export interface IOfferSettingsBuildBranding {
  title: string;
  splash_title: string;
  splash_subtitle: string;
  primary_color: string;
  bar_color: string;
  selected_button_color: string;
  unselected_button_color: string;
  background_splash_color: string;
  splash_text_color: string;
  splash_subtitle_color: string;
}

export interface IOfferSettingsBuildExternal {
  type: string;
  enabled: boolean;
  key: string;
  secret: string;
  domain: string;
}

export interface IOfferSettingsBuildSocial {
  type: string;
  enabled: boolean;
  key: string;
}

export interface IOfferSettingsBuildAnalytic {
  type: string;
  enabled: boolean;
  key: string;
}

export interface IOfferSettingsBuildIos {
  bundle: string;
  team: string;
  apple_app_id: string;
}

export interface IOfferSettingsBuild {
  id: ID;
  type: OfferSettingsBuildTypeEnum;
  bundle_id: string;
  features: IOfferSettingsBuildFeatures;
  interface: IOfferSettingsBuildInterface;
  branding: IOfferSettingsBuildBranding;
  externals: IOfferSettingsBuildExternal[];
  socials: IOfferSettingsBuildSocial[];
  analytics: IOfferSettingsBuildAnalytic[];
  [key: string]:
    ID |
    string |
    OfferSettingsBuildTypeEnum |
    IOfferSettingsBuildFeatures |
    IOfferSettingsBuildInterface |
    IOfferSettingsBuildBranding |
    IOfferSettingsBuildExternal[] |
    IOfferSettingsBuildSocial[] |
    IOfferSettingsBuildAnalytic[];
}

export interface IOfferSettingsBuildExternalFormValues {
  platform: string;
  type: string;
  enabled: boolean;
  key: string;
  secret: string;
  domain: string;
  [key: string]: string | boolean;
}

export interface IOfferSettingsBuildSocialFormValues {
  platform: string;
  type: string;
  enabled: boolean;
  key: string;
  [key: string]: string | boolean;
}

export interface IOfferSettingsBuildAnalyticFormValues {
  platform: string;
  type: string;
  enabled: boolean;
  key: string;
  [key: string]: string | boolean;
}

export interface IOfferSettingsBuildFormValues {
  android_bundle_id: string;
  android_features_corporate: boolean;
  android_features_in_app_purchase: boolean;
  android_features_books: boolean;
  android_features_content_table: boolean;
  android_features_documents: boolean;
  android_features_banners: boolean;
  android_features_badges: boolean;
  android_features_wishlist: boolean;
  android_features_comments: boolean;
  android_features_push_notification: boolean;
  android_features_local_notification: boolean;
  android_features_required_login: boolean;
  android_features_socials: boolean;
  android_features_pins: boolean;
  android_features_user_groups: boolean;
  android_features_analytic: boolean;
  android_features_app_rate: boolean;
  android_interface_title_showcase: string;
  android_interface_title_my_collection: string;
  android_interface_title_documents: string;
  android_interface_title_my_documents: string;
  android_branding_title: string;
  android_branding_splash_title: string;
  android_branding_splash_subtitle: string;
  android_branding_primary_color: string;
  android_branding_bar_color: string;
  android_branding_selected_button_color: string;
  android_branding_unselected_button_color: string;
  android_branding_background_splash_color: string;
  android_branding_splash_text_color: string;
  android_branding_splash_subtitle_color: string;
  android_branding_primary_dark_color: string;
  android_branding_primary_light_color: string;
  android_branding_accent_color: string;
  android_branding_accent_dark_color: string;
  android_branding_text_primary_color: string;
  android_branding_secondary_text_color: string;
  android_branding_icons_color: string;
  android_branding_divider_color: string;
  android_branding_background_light_color: string;
  ios_bundle_id: string;
  ios_features_corporate: boolean;
  ios_features_in_app_purchase: boolean;
  ios_features_books: boolean;
  ios_features_content_table: boolean;
  ios_features_documents: boolean;
  ios_features_banners: boolean;
  ios_features_badges: boolean;
  ios_features_wishlist: boolean;
  ios_features_comments: boolean;
  ios_features_push_notification: boolean;
  ios_features_local_notification: boolean;
  ios_features_required_login: boolean;
  ios_features_socials: boolean;
  ios_features_pins: boolean;
  ios_features_user_groups: boolean;
  ios_features_analytic: boolean;
  ios_features_app_rate: boolean;
  ios_interface_title_showcase: string;
  ios_interface_title_my_collection: string;
  ios_interface_title_documents: string;
  ios_interface_title_my_documents: string;
  ios_branding_title: string;
  ios_branding_splash_title: string;
  ios_branding_splash_subtitle: string;
  ios_branding_primary_color: string;
  ios_branding_bar_color: string;
  ios_branding_selected_button_color: string;
  ios_branding_unselected_button_color: string;
  ios_branding_background_splash_color: string;
  ios_branding_splash_text_color: string;
  ios_branding_splash_subtitle_color: string;
  externals: IOfferSettingsBuildExternalFormValues[];
  socials: IOfferSettingsBuildSocialFormValues[];
  analytics: IOfferSettingsBuildAnalyticFormValues[];
  [key: string]:
    string |
    boolean |
    IOfferSettingsBuildExternalFormValues[] |
    IOfferSettingsBuildSocialFormValues[] |
    IOfferSettingsBuildAnalyticFormValues[];
}

export interface IOfferSettingsBuildFeaturesInput {
  corporate: boolean;
  in_app_purchase: boolean;
  books: boolean;
  content_table: boolean;
  documents: boolean;
  banners: boolean;
  badges: boolean;
  wishlist: boolean;
  comments: boolean;
  push_notification: boolean;
  local_notification: boolean;
  required_login: boolean;
  socials: boolean;
  pins: boolean;
  user_groups: boolean;
  analytic: boolean;
  app_rate: boolean;
}

export interface IOfferSettingsBuildInterfaceInput {
  title_showcase: string;
  title_my_collection: string;
  title_documents: string;
  title_my_documents: string;
}

export type IOfferSettingsBuildAndroidBrandingInput = {
  primary_dark_color: string;
  primary_light_color: string;
  accent_color: string;
  accent_dark_color: string;
  text_primary_color: string;
  secondary_text_color: string;
  icons_color: string;
  divider_color: string;
  background_light_color: string;
} & IOfferSettingsBuildIosBrandingInput;

export interface IOfferSettingsBuildIosBrandingInput {
  title: string;
  splash_title: string;
  splash_subtitle: string;
  primary_color: string;
  bar_color: string;
  selected_button_color: string;
  unselected_button_color: string;
  background_splash_color: string;
  splash_text_color: string;
  splash_subtitle_color: string;
}

export interface IOfferSettingsBuildExternalInput {
  type: string;
  enabled: boolean;
  key: string;
  secret: string;
  domain: string;
}

export interface IOfferSettingsBuildSocialInput {
  type: string;
  enabled: boolean;
  key: string;
}

export interface IOfferSettingsBuildAnalyticInput {
  type: string;
  enabled: boolean;
  key: string;
}

export interface IOfferSettingsBuildInput {
  bundle_id?: string | null;
  features?: IOfferSettingsBuildFeaturesInput | null;
  interface?: IOfferSettingsBuildInterfaceInput | null;
  branding?:
    null |
    IOfferSettingsBuildAndroidBrandingInput |
    IOfferSettingsBuildIosBrandingInput;
  externals?: IOfferSettingsBuildExternalInput[] | null;
  socials?: IOfferSettingsBuildSocialInput[] | null;
  analytics?: IOfferSettingsBuildAnalyticInput[] | null;
  [key: string]:
    string |
    null |
    undefined |
    IOfferSettingsBuildFeaturesInput |
    IOfferSettingsBuildInterfaceInput |
    IOfferSettingsBuildIosBrandingInput |
    IOfferSettingsBuildAndroidBrandingInput |
    IOfferSettingsBuildExternalInput[] |
    IOfferSettingsBuildSocialInput[] |
    IOfferSettingsBuildAnalyticInput[];
}
