import React from 'react';
import { get } from 'lodash';
import { Input } from 'reactstrap';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import moment from 'moment';
import { FormikProps } from 'formik';
import { ModalBannerEditor } from 'features/ui/BannerEditor';
import FormFrame from 'features/ui/FormFrame';
import FormRow from 'features/ui/FormRow';
import ImageUploader from 'features/ui/ImageUploader';
import Switch from 'features/ui/Switch';
import CategoriesSelect from 'features/categories/lib/CategoriesSelect';
import OffersSelectWithButtons from 'features/offers/lib/OffersSelectWithButtons';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import { InfoRow } from 'features/ui/InfoRow';
import { IImage } from 'features/app/types';
import ResourceLink from '../ResourceLink';
import DatePicker from 'features/ui/DatePicker';
import TimePicker from 'features/ui/TimePicker';
import { makeBannerUrl } from '../url';
import {
  IBanner,
  IBannerFormValues,
} from '../../types';
import {
  BANNER_HEIGHT_STANDARD,
  BANNER_HEIGHT_WIDE,
  BANNER_WIDTH_STANDARD,
  BANNER_WIDTH_WIDE,
} from '../../consts';
import styles from './styles.module.scss';
import { Moment } from 'features/types';
import CreatorsSelect from 'features/creators/lib/CreatorsSelect';

interface IBannerFormProps extends FormikProps<IBannerFormValues>, WithTranslation {
  banner?: IBanner;
}

interface IBannerFormState {
  imageFile: string | null;
}

class BaseBannerForm extends React.Component<IBannerFormProps, IBannerFormState> {
  constructor(props: IBannerFormProps) {
    super(props);
    const { image } = props.values;
    this.state = {
      imageFile: typeof image === 'string' ? image : null,
    };
  }

  onChangeImage = (e: any) => {
    if (e.target.validity.valid) {
      this.props.handleChange(e);
      this.props.setFieldValue('image', e.target.files[0]);
    }
  };

  onChangeResourceLink = (value: string) => {
    this.props.setFieldValue('resource_link', String(value));
  };

  onChangeOffers = (ids: ID[]) => {
    this.props.setFieldValue('offers', ids.slice());
  };

  onBlurResourceLink = () => {
    this.props.setFieldTouched('resource_link', true);
  };

  onSaveBanner = (blob: Blob | null) => {
    if (blob) {
      const file = new File([blob], 'some.png', { type: 'image/png', lastModified: Date.now() });
      const reader = new FileReader();
      reader.addEventListener('load', (e) => {
        if (reader.result) {
          this.setState(
            { imageFile: reader.result as string },
            () => this.props.setFieldValue('image', file, false),
          );
        }
      });
      reader.readAsDataURL(blob);
    }
  }

  renderResourceLinkInputURL() {
    const {
      handleBlur,
      handleChange,
      t,
      values,
    } = this.props;
    return (
      <FormRow label={t('resource_link')} id="resource_link" text="https://some-address.com" required>
        <Input
          type="text"
          id="resource_link"
          name="resource_link"
          value={values.resource_link}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormRow>
    );
  }

  renderResourceLinkInputCreator() {
    const {
      t,
      values,
    } = this.props;
    return (
      <FormRow
        label={t('resource_link')}
        id="resource_link"
        required
      >
        <CreatorsSelect
          type="author"
          id="author"
          name="author"
          value={parseInt(values.resource_link, 10) || []}
          onChange={this.onChangeResourceLink}
          onBlur={this.onBlurResourceLink}
        />
      </FormRow>
    );
  }

  renderResourceLinkInputCategory() {
    const {
      t,
      values,
    } = this.props;
    return (
      <FormRow label={t('resource_link')} id="resource_link" required>
        <CategoriesSelect
          id="resource_link"
          name="resource_link"
          value={parseInt(values.resource_link || '0', 10)}
          onChange={this.onChangeResourceLink}
          onBlur={this.onBlurResourceLink}
          isMulti={false}
        />
      </FormRow>
    );
  }

  renderResourceLinkInputItem() {
    const {
      t,
      values,
    } = this.props;
    return (
      <FormRow label={t('resource_link')} id="resource_link" required>
        <ItemsSelect
          id="resource_link"
          name="resource_link"
          value={parseInt(values.resource_link || '0', 10)}
          isMulti={false}
          onBlur={this.onBlurResourceLink}
          onChange={this.onChangeResourceLink}
        />
      </FormRow>
    );
  }

  renderGeneratedLink() {
    const {
      banner,
      t,
    } = this.props;
    if (banner) {
      return (
        <InfoRow label={t('generated_link')} text={t('forms:updating_after_saving')}>
          <ResourceLink banner={banner} />
        </InfoRow>
      );
    }
    return null;
  }

  collectOffersColors() {
    const {
      banner,
      t,
    } = this.props;
    const branding = get(banner, 'offers[0].settingsBranding', null);
    if (branding) {
      return Object
        .entries(branding)
        .filter(pair => pair[1] && typeof pair[1] === 'string')
        .map(pair => ({
          caption: t(`offers:settingsBranding:${pair[0]}`),
          color: pair[1],
        }));
    }
    return [];
  }

  parseImagesLinks(images: IImage[]) {
    return images.map((image: any, index: number) => (
      <p key={index} className={styles.imageLinks}>
        <a href={image.path} target="_blank">
          {image.height}x{image.width}
        </a>
      </p>
    ));
  }

  onChangeDate = (date: Moment | null, field: string) => {
    const { setFieldValue } = this.props;
    setFieldValue(field, date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '');
  };

  renderDatesInput() {
    const {
      t,
      values,
    } = this.props;
    const {
      started_at,
      finished_at,
    } = values;
    const startedAt = started_at ? moment(started_at).format('YYYY-MM-DDTHH:mm:ss') : null;
    const finishedAt = finished_at ? moment(finished_at).format('YYYY-MM-DDTHH:mm:ss') : null;

    return (
      <>
        <FormRow
          key="started_at"
          label={t('started_at')}
          id="started_at"
        >
          <DatePicker
            id="event.started_at"
            value={Date.parse(startedAt)}
            onChange={(date: Moment | null) => this.onChangeDate(date, 'started_at')}
          />
          <TimePicker
            onChange={(date: Moment | null) => this.onChangeDate(date, 'started_at')}
            value={Date.parse(startedAt)}
          />
        </FormRow>
        <FormRow
          key="finished_at"
          label={t('finished_at')}
          id="finished_at"
        >
          <DatePicker
            id="finished_at"
            onChange={(date: Moment | null) => this.onChangeDate(date, 'finished_at')}
            value={Date.parse(finishedAt)}
          />
          <TimePicker
            onChange={(date: Moment | null) => this.onChangeDate(date, 'finished_at')}
            value={Date.parse(finishedAt)}
          />
        </FormRow>
      </>
    );
  }

  render () {
    const {
      banner,
      handleBlur,
      handleChange,
      t,
      values,
    } = this.props;

    const BANNER_WIDTH = values.format === 'standard' ? BANNER_WIDTH_STANDARD :BANNER_WIDTH_WIDE;
    const BANNER_HEIGHT = values.format === 'standard' ? BANNER_HEIGHT_STANDARD : BANNER_HEIGHT_WIDE;
    const { imageFile } = this.state;
    const offersColors = this.collectOffersColors();
    return (
      <FormFrame
        id={banner ? banner.id : undefined}
        cancelLink={makeBannerUrl()}
        {...this.props}
      >
        <FormRow label={t('name')} id="name" required>
          <Input
            type="text"
            id="name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('weight')} id="weight">
          <Input
            type="number"
            id="weight"
            name="weight"
            value={values.weight === undefined ? '' : values.weight}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <hr />

        <FormRow label={t('resource_type')} id="resource_type">
          <Input
            id="resource_type"
            name="resource_type"
            type="select"
            value={values.resource_type}
            onChange={handleChange}
          >
            <option value="category">{t('resource_type_category')}</option>
            <option value="item">{t('resource_type_item')}</option>
            <option value="url">{t('resource_type_url')}</option>
            <option value="creator">{t('resource_type_creator')}</option>
          </Input>
        </FormRow>

        {values.resource_type === 'category' ? this.renderResourceLinkInputCategory() : null}
        {values.resource_type === 'item' ? this.renderResourceLinkInputItem() : null}
        {values.resource_type === 'url' ? this.renderResourceLinkInputURL() : null}
        {values.resource_type === 'creator' ? this.renderResourceLinkInputCreator() : null}
        {this.renderGeneratedLink()}

        <hr />
        {this.renderDatesInput()}
        <hr />

        <FormRow label={t('is_active')} id="is_active">
          <Switch
            id="is_active"
            name="is_active"
            checked={values.is_active}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>

        <FormRow label={t('format')} id="format">
          <Input
            id="format"
            name="format"
            type="select"
            value={values.format}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="standard">{t('standard')}</option>
            <option value="wide_5:2">{t('wide_5-2')}</option>
          </Input>
        </FormRow>

        <FormRow
          label={t('image')}
          id="image"
          text={t('forms:expected_size', { width: BANNER_WIDTH, height: BANNER_HEIGHT })}
          required
          shouldUpdate={() => true}
        >
          <ImageUploader
            id="image"
            name="image"
            value={imageFile}
            onChange={this.onChangeImage}
            onBlur={handleBlur}
            imageWidth={450}
            imageHeight={177}
          />
          {banner && banner.images && banner.images.length ? this.parseImagesLinks(banner.images) : null}
          <ModalBannerEditor
            canvasWidth={BANNER_WIDTH}
            canvasHeight={BANNER_HEIGHT}
            onSave={this.onSaveBanner}
            colors={offersColors}
          />
        </FormRow>

        <FormRow key="offers" label={t('offers:offers')} id="offers" text={t('offers:offers')}>
          <OffersSelectWithButtons
            id="offers"
            name="offers"
            value={values.offers}
            onChange={this.onChangeOffers}
          />
        </FormRow>
      </FormFrame>
    );
  }
}

export default withTranslation('banners')(BaseBannerForm);
