export const BLOCK_ICON = 'fa fa-simplybuilt';

export const blockParams = {
  footer_with_app_link: {
    params: [],
  },
  thin_block_with_link: {
    params: ['image', 'bg_color', 'link'],
  },
  important_item: {
    params: ['bg_color', 'item'],
  },
  items_with_bg_and_image: {
    params: ['image', 'bg_color', 'items'],
  },
  in_app_stories: {
    params: ['feed_name'],
  },
  grid_items: {
    params: ['items'],
  },
  items: {
    params: ['items'],
  },
  banners_with_button_links: {
    params: ['references'],
  },
  popular_items: {
    params: [],
  },
  latest_items: {
    params: [],
  },
  popular_requests_items: {
    params: [],
  },
};
