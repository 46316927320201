import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  client,
  gql,
} from 'features/graphql';
import FixedOnTheRight from 'features/ui/FixedOnTheRightContainer';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  ISubscription,
  ISubscriptionsQueryResponse,
} from 'features/subscriptions/types';
import {
  mapEntityToOptions,
  ReactSelectWithId,
} from 'features/ui/ReactSelect';

const QUERY = gql`
  query getSubscriptionsForSelect(
    $id: [ID]
    $pageNum: Int
    $perPage: Int
    $filterByFields: SubscriptionFilterByFields
  ) {
    SubscriptionQuery(
      id: $id,
      page: $pageNum
      perPage: $perPage
      filterByFields: $filterByFields
    ) {
      items {
        name
        id
      }
    }
  }
`;

interface ISubscriptionsSelectProps extends ISharedSelectProps {
  data: ISubscription[];
}

interface ISubscriptionsSelectState {
  options: ISelectOption[];
}

class SubscriptionsSelect extends React.PureComponent<ISubscriptionsSelectProps, ISubscriptionsSelectState> {
  static getDerivedStateFromProps(props: any) {
    return {
      options: mapEntityToOptions<ISubscription>(props.data, 'name'),
    };
  }

  state = {
    options: [],
  };

  loadOptions = (value: string) => {
    let vars = {};
    if (!isNaN(+value)) {
      vars = {
        id: value.trim(),
      };
    } else {
      vars = {
        filterByFields: {
          name: {
            value,
            operator: 'like',
            condition: 'or',
          },
        },
      };
    }

    return client.query<ISubscriptionsQueryResponse>({
      query: QUERY,
      variables: {
        pageNum: 1,
        perPage: 1000,
        ...vars,
      },
    }).then((res) => {
      if (res.data && res.data.SubscriptionQuery) {
        const subscriptions = res.data.SubscriptionQuery.items;
        return subscriptions.map((subscription: ISubscription) => ({
          value: +subscription.id,
          label: String(subscription.name),
        })).sort((a: ISelectOption<number>, b: ISelectOption<number>) => (a.value - b.value));
      }
      return [];
    });
  };

  onClearSubs = () => {
    const { onChange } = this.props;
    onChange([]);
  };

  onAddAllSubs = () => {
    const { onChange } = this.props;
    client.query<ISubscriptionsQueryResponse>({
      query: QUERY,
      variables: {
        page: 1,
        perPage: 500000,
      },
    })
      .then((result) => {
        if (result.data && result.data.SubscriptionQuery) {
          onChange(result.data.SubscriptionQuery.items.map(subscription => subscription.id));
        }
      });
  };

  render() {
    const {
      id,
      name,
      value,
      onBlur,
      onChange,
      isMulti,
    } = this.props;

    const { options } = this.state;
    const side = (
      <React.Fragment>
        <a
          className="btn btn-warning"
          onClick={this.onAddAllSubs}
          title="Добавляет все Подписки"
        >
          Добавить Все
        </a>
        <a
          className="btn btn-warning"
          onClick={this.onClearSubs}
        >
          Очистить
        </a>
      </React.Fragment>
    );
    if (isMulti) {
      return (
        <FixedOnTheRight side={side}>
          <ReactSelectWithId
            id={id}
            name={name}
            options={options}
            isMulti={isMulti}
            async
            loadOptions={this.loadOptions}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            minSearchLength={1}
          />
        </FixedOnTheRight>
      );
    }
    return (
      <ReactSelectWithId
        id={id}
        name={name}
        options={options}
        isMulti={!!isMulti}
        async
        loadOptions={this.loadOptions}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        minSearchLength={1}
      />
    );
  }
}

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, ISubscriptionsSelectProps, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.SubscriptionQuery ? data.SubscriptionQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, ISubscriptionsSelectProps, IListQueryVariables, any>(QUERY, {
  props: mapResultsToProps,
  options: ownProps => ({
    variables: {
      id: ownProps.value || undefined,
      pageNum: 1,
      perPage: 1000,
      searchByFields: null,
    },
  }),
})(SubscriptionsSelect);
