import React from 'react';
import {
  WithTranslation,
  withTranslation,
} from 'react-i18next';
import ApiTokens from './ApiTokens';
import PinCodes from './PinCodes';
import { IOffer } from '../types';

interface ITabOthersProps extends WithTranslation {
  offer: IOffer;
  hasControl?: boolean;
  refetch?: (vars?: any) => Promise<any>;
}

class TabOthers extends React.Component<ITabOthersProps> {
  static defaultProps = {
    hasControl: true,
  };

  render() {
    const {
      t,
      offer,
      refetch,
      hasControl,
    } = this.props;

    if (!offer) {
      return null;
    }
    return (
      <React.Fragment>
        <h1>{t('tokens')}</h1>
        <ApiTokens
          tokens={offer.tokens}
          refetch={refetch}
          offerId={offer.id}
          hasControl={hasControl}
        />
        <h1>{t('pins')}</h1>
        <PinCodes
          refetch={refetch}
          offerId={offer.id}
          hasControl={hasControl}
        />
      </React.Fragment>
    );
  }
}

export default withTranslation('offers')(TabOthers);
